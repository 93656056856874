.layer-info {
	background: white;
  color: @text-color-primary;
  font-weight: 500;

	.title {
		font-size: @font-size-l;
		color: @text-color-primary;
	}

	.sub {
		font-size: @font-size-m;
		color: @text-color-secondary;
	}

  &.layer-info-inverted {
    box-shadow: none;

    &, .leaflet-popup-tip {
      background: @map-detail-dark-overlay;
    }

    &, h4, .title, a {
      color: white;
    }
    .sub {
      color: @smoke-80;
    }

  }
}

.layer-info-floating {
  position: absolute;
  border-radius: 3px;
  padding: 1em 1.5em;
  z-index: 6;
}

.layer-title {
  .layer-info-floating();

  top: 1em;

  h4 {
    margin: 0;
  }
}

.layer-legend {
  margin-top: 1em;
  margin-bottom: 1em;
}

.layer-legend-floating {
  .layer-info-floating();
	bottom: 0;
	width: 600px;
  left: 50%;
	margin-left: -300px;
}


.layer-legend-ticks {
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: auto;
    margin: 0 0 .5em;

    .layer-legend-tick {
        font-size: @font-size-xs;
        text-transform: uppercase;

        &:last-child {
            text-align: right;
        }
    }
}

@media only screen and (max-width: @screen-md-min) {
  .layer-legend-floating {
    width: 75%;
    left: 0;
    margin-left: 15px;
  }
}
