/*
*	TEXT
*
*	Styles for text elements (besides headings) like <p>, <li>, etc
*
*/



// LINKS AND ANCHORS

a {
    // for backbone anchors w/o hrefs
    cursor: pointer;

    color: @blue;

    text-decoration: none;

    &:hover {
        color: @text-color-primary;
        text-decoration: underline;
    }

    &.underline {
      text-decoration: underline !important;
      &:hover {
        text-decoration: underline !important;
      }
    }

    &.no-underline:hover {
      text-decoration: none;
    }
}

.text-standard {
  .font-weight-500;
  color: @text-color-primary;
}

.gray-link {
    .ui-control();
    color: @text-color-secondary;
    font-weight: 500;
    display: block;
    margin: 0 0 1em;
}

.support-link {
    color: @text-color-secondary;
    text-decoration: underline;
}

.red-link {
  &, &:hover {
    color: @red;
  }
}

.blue-link {
  &, &:hover {
    color: @blue
  }
}

.light-blue-link {
  &, &:hover {
    color: lighten(@blue, 30%);
  }
}


// PARAGRAPH

p {
    margin: 0 0 1em;

    a {
        text-decoration: underline;
    }
}


// LISTS
// basically a reset

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
    }

    // bring back listy-looking lists if needed
    &.ordered-list,
    &.unordered-list {
        margin: 0 0 1em;
        padding: 0;
        font-weight: 500;

        li {
            margin: 0 0 .5em 2em;
        }
    }
    &.ordered-list {
        list-style-type: ordinal;
    }
    &.unordered-list {
        list-style: disc;
    }

}

.fl-text-right-arrow {
    font-family: verdana, arial, sans-serif;
}

.blue-text {
    color: @blue
}
.gray-text {
    color: @smoke-60
}
.green-text {
    color: @green
}
.red-text {
    color: @red
}

.muted-text {
    color: @clear-smoke-30
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}
