
.note-textarea {
  width: 100%;
  max-width: 100%;
}

.floating-cancel {
  position: fixed;
  bottom: 50px;
  margin-left: 100px;
}

.maps-image-container {
  height: 160px;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
  background-color: white;
  position: relative;
  border-radius: 4px;

  .add-location-placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../../../assets/images/notes/FarmImage.png');
    color: white;
    padding-top: 70px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }

  // WRAPPERS - basics
  .note-map-image {
    position: relative;
    display: block;
    float: left;
    width: 100%;  // default
    height: 160px; // default

    // center icon
    .note-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -4px 0 0 -4px;
    }

    .remove-icon {
      padding: 5px 8px;
      color: white;
      background-color: rgba(0, 0, 0, .5);
      border-top-right-radius: 8px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      transition: background-color 200ms ease-in;

      &:hover {
        background-color: rgba(0, 0, 0, .7);
      }
    }
  }

  // WRAPPERS - sizing and hiding

  // CHILDREN - basics

  .map {
    display: block;
    float: left;
    background-size: cover;
    background-position: center;
  }

  // CHILDREN - sizing

  // map sizing
  // there's only one, so...
  .map {
    width: 100%;
    height: 100%;

//    border-radius: 4px;

    -webkit-box-shadow: inset 0px 0px 1px 1px @clear-smoke-30;
    -moz-box-shadow: inset 0px 0px 1px 1px @clear-smoke-30;
    box-shadow: inset 0px 0px 1px 1px @clear-smoke-30;
  }
}
