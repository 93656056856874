/*
*	ANIMATION
*
*	Define CSS animations for use in other files
*
*/


// PROGRESS BAR STRIPES
// for "spinning" progress bars

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}


// LOADING SPINNER

@-webkit-keyframes spinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(720deg);
  }
}
@-moz-keyframes spinner {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(720deg);
  }
}
@-ms-keyframes spinner {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(720deg);
  }
}
@-o-keyframes spinner {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(720deg);
  }
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}
 
