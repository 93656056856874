.Well {
  .clear-fix();

  padding: 24px;
  background-color: @smoke-98;
  border-radius: 2px;
  border: 1px solid @smoke-80;

  .Well__Divider {
    border-bottom: 1px solid @smoke-80;
    border-top: none;
    height: 0;
  }
}
