
.welcome-user-modal {
  .fl-modal-window {
    margin-top: 180px !important;
  }

  .img-header {
    height: 60px;
  }

  .main-btn {
    padding: .6em 1.5em;
  }

  .text-header {
    font-size: @font-size-xl !important;
    font-weight: 500 !important;
  }

  .sub {
    color: @text-color-secondary;
    margin-bottom: 2em !important;
  }
}

.mobile-links {
  font-size: @font-size-m;
  text-align: center;

  a {
    display: inline-block;
    color: @text-color-secondary;
  }

  .left {
    margin-right: 30px !important;
  }
}
