/*
*	FIELD
*
*	Styles for field index and detail
*
*/

@item-card-size-small: 140px;
@item-card-size-xsmall: 115px;

.enterprise-filter{
  margin-bottom: 1em;
}

.field-detail-link {
  cursor: pointer;
}

.field-list-item {
  font-weight: 500;
  position: relative;
  color: @text-color-secondary;

  h3 {
    color: @text-color-primary;
    font-size: @font-size-l;
    line-height: @font-size-xl;
  }
}

.add-crop {
  z-index: 100;
}

.item-card-content {
  padding: 1em 0;
}

.crop-block {
  margin: 0 0 1em;
}

.point-field-overlay {
  height: 10rem;

  h3 {
    color: #fff;
    padding-top: 39px;
  }
  z-index: 102;
}

.sidebar-map-container .point-field-overlay {
  height: 100%;

  h3 {
    padding-top: 30%;
  }
}

.field-group {
  &.details, &.list {
    .field-list-item {
      border-bottom: 1px solid @smoke-90;
      &:hover {
        background-color: @smoke-98;
      }
    }
  }
  &.details {
    .field-list-item {
      padding: 1.5em;
    }
    h3 {
      margin: 0 0 .5em;
      line-height: 1;
    }
  }
  &.list {
    .field-list-item {
      padding: 10px;
    }
    h3 {
      margin: 0;
      display: inline-block;
    }
    .recent-rainfall-badge-wrapper {
      margin: 0;
    }
    .recent-rainfall-badge {
      margin: 0;
    }
  }
  &.cards {
    h3 {
      margin: 0 0 .5em;
    }
    .field-list-item {
      outline: 0;
      position: relative;
      padding: 2em 1em 0;
      display: inline-block;
      vertical-align: top;
      font-weight: 500;
      width: 10rem;
      box-sizing: content-box;
    }
    .badge {
      margin-top: .5em;
      margin-right: 2em;
      &.recent-rainfall-badge {
        margin-right: .4em;
      }
    }
    svg {
      z-index: 100;
    }
    .acreage {
      display: inline-block;
      position: absolute;
      right: 0.4em;
      bottom: 0.4em;
      color: white;
      z-index: 100;
      text-shadow: 0.5px 0.5px 2px @smoke-10;
      font-size: 1.05em;
    }
  }
  .resource-icon-wrapper {
    margin: 0;
    &.map-image {
      max-width: 10rem;
    }
  }
  .acreage-overlay {
    position: relative;
  }
  .acreage-overlay-gradient-container {
    height: 10rem;
    width: 10rem;
  }
  .acreage-overlay-gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4yNSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: linear-gradient(to bottom,  hsla(0,0%,0%,0) 0%,hsla(0,0%,0%,0.25) 100%);
  }
}


// aspect widget on field detail page

.field-detail-widget {
    padding: 1em 0 2em;
    border-bottom: 1px solid @separator-color;

    //click here to drill down into aspets
    .field-detail-widget-header {
        display: block;
        &:hover {
            text-decoration: none;
        }
    }

}


// links to aspect details

.heading-more-link {
	text-decoration: none; // use underline instead
	font-size: 13px;
	box-shadow: 0 1px;
	display: inline-block;
	font-weight: 500;
	color: @smoke-40;
	opacity: .7;
  float: right;

	&:hover {
		opacity: 1;
		text-decoration: none;
	}
}

.sidebar-map-container {
  position: relative;

  .sidebar-map-edit-link {
    position: absolute;
    bottom: 1em;
    right: 1em;
    z-index: 1000;
  }
}

.sidebar-h1, .sidebar-p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-h1 {
  padding-bottom: 5px;
}

.sidebar-p{
  max-width: 150px;
  display: inline-block;
  vertical-align: bottom;
}

@media only screen and (max-width: @screen-md-min) {
  .heading-more-link.mobile-margin {
    margin: 10px 0;
  }

  .field-group.cards .field-list-item {
    padding-top: 1em;
  }
  .field-group.details .field-list-item {
    padding-left: 0;
    padding-right: 0;
  }
  .add-crop {
    margin: 10px 0;
  }
}

@media only screen and (max-width: @screen-sm-min) and (min-width: 361px) {
  .field-group.cards .field-list-item {
    width: @item-card-size-small;

    .point-field-overlay {
      height: @item-card-size-small;
      h3 { padding-top: 33px; }
    }
  }

  .field-group .acreage-overlay-gradient-container {
    width: @item-card-size-small;
    height: @item-card-size-small;
  }
}

@media only screen and (max-width: 360px) {
  .field-group.cards .field-list-item {
    width: @item-card-size-xsmall;

    .point-field-overlay {
      height: @item-card-size-xsmall;
      h3 { padding-top: 20px; }
    }
  }

  .field-group .acreage-overlay-gradient-container {
    width: @item-card-size-xsmall;
    height: @item-card-size-xsmall;
  }
}

@media print {
  .fields-list-closed {
      .js-view-picker {
        display: none;
      }
  }
  .field-group {
    .col {
      display: block;
      width: 100%;
    }
    .col:first-child {
      page-break-after: avoid;
    }
    h2.heading-collapsable {
      display: block;
      min-width: 100%;
      width: 100%;
      padding-left: 0;
      border-bottom: 1px solid hsl(216, 10%, 90%);
      .heading-edit {
        display: none;
      }
    }
    h2.heading-collapsable:after {
      display: none;
    }
    .acreage-overlay-gradient {
      display: none;
    }
    .recent-rainfall-badge {
      padding-left: 0;
    }
  }
  .field-group.details {
    .field-list-item .col {
      width: 25%;
      display: inline-block;
    }
  }
  .field-group.list {
    .field-list-item .col {
      width: 25%;
      display: inline-block;
    }
  }
}
