/*
*	STEP PROGRESS
*
*	Styles for numbered stepping.
*
*/


.step-progress {
    .clear-fix();
    margin: 1em 0 2em;
    position: relative; // contain progress bar

    .progress {
        position: absolute;
        left: 12.5%;
        right: 12.5%;
        top: 1em;
        margin: -5px 0 0;
    }
    .step {
        text-align: center;
        float: left;
        width: 25%;
        z-index: 1;
        position: relative; // get z index

        .icon {
            margin: 0 auto;
            border-radius: 100px;
            background: @smoke-92;
            color: @text-color-primary;
            width: 2em;
            height: 2em;
            line-height: 2em;
            font-weight: 500;
        }
        .label {
            margin: .5em auto;
            font-size: @font-size-xs;
            font-weight: 500;
            text-transform: uppercase;
        }

        &.complete .icon {
            background: @text-color-primary;
            color: white;
        }
    }
}

// special contrast styles for inside callouts
.callout .step-progress {
    .progress {
        background: white;
    }
    .step {
        .icon {
            background: white;
        }
        &.complete .icon {
            background: @text-color-primary;
            color: white;
        }
    }
}
