/*
*   CHARTS
*
*   Styles for d3 line graphs, pie charts, crop timelines, etc
*
*/


// CHART WRAPPER

.chart-wrap-box {
    margin: 0 0 2em;
    position: relative;

    // hide chart border when svg is generating a border "inside" the axis
    &.chart-wrap-box-clear {
        .chart-wrap-box-inner {
            margin: 0 -18px;
            border: none;
            .box-shadow(none);
            padding-top: 0;
        }
    }

    .chart-type-picker {
        position: absolute;
        right: 6px;
        top: 8px;
        font-weight: 500;
        color: @text-color-primary;
        font-size: @font-size-s;
    }
    .chart-info {
        position: absolute;
        right: 10px;
        top: 5px;
        font-weight: 500;
        color: @text-color-primary;
        font-size: @font-size-s;
        z-index: 100;
        line-height: 24px;

        .lbl {
            margin: 0 0 0 .6em;
        }
        .value {
            margin: 0 0 0 0;
        }
    }
    .chart-wrap-box-inner {
        background: white;
        border-radius: 2px;
        border: 1px solid @smoke-80;
        .box-shadow( 0 1px 3px @smoke-92 );
        //overflow: hidden; // prevents charts from overflowing on resize, but breaks popovers
        position: relative;
        padding: 10px;
    }
}


// CHARTS

.chart {
    height: 230px;
    position: relative;

    // loading state
    &.loading:after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: url("../../../assets/images/template/indicator.gif");
        background: white;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
    }

    &.chart-l {
        height: 300px;
    }

    // chart types
    &.histogram {
        height: 115px;
        margin-top: -30px;

        .rainfall-histogram-rect {
            fill: @blue;
        }
    }

    .chart-toggle {
        position: absolute;
        top: 0;
        right: 0;
    }

    .axis {
        shape-rendering: crispEdges;
        fill: none;

        line {
            fill: none;
            stroke: @smoke-92;
            stroke-width: 1px;
        }

        text {
            fill: @text-color-secondary;
            text-transform: uppercase;
            font-size: @font-size-xs;
            font-weight: 500;
        }

        path.domain {
            display: none;
            fill-opacity: 0;
        }

        &.x .tick {
            line {
                stroke-dasharray: 5;
            }
        }
    }

    .plot {

        // TODO: deprecate these chart shapes

        path.line {
            fill: none;
            stroke-width: 3px;

            &.rainfall {
                stroke: @blue;
            }
        }

        path.area {
            stroke-width: 0;
            fill-opacity: .3;

            &.rainfall {
                fill: @light-blue;
                fill-opacity: 20%;
            }
        }

        rect.benchmark {
            fill: @smoke-20;
        }

        path.slice {
            stroke: @white;
            stroke-width: 1px;
            cursor: pointer;
        }
    }


    // single-buyer prices
    .prices {

        path.futures-area {
            fill-opacity: .1;
            fill: @smoke-70;
        }
        path.futures-line {
            fill-opacity: 0;
            stroke-width: 2;
            stroke: @smoke-80;
        }
        path.cash {
            fill-opacity: 0;
            stroke-width: 2;
            stroke: @smoke-50;
        }
    }

}

// CHART SHAPES

// bars on bar charts
.chart-rect {
    stroke-width: 0.4px; // keep weird white in-between lines from appearing (at least in Chrome)
    fill: @smoke-70;
    stroke: @smoke-70;

    &.chart-rect-rainfall {
        fill: @blue;
        stroke: @blue;
    }
    &.chart-rect-gdd {
        fill: @red;
        stroke: @red;

        &.chart-rect-heat-stress {
            fill: @dark-red;
            stroke: @dark-red;
        }
    }

}

// lines on line graphs
.chart-line {
    stroke: @smoke-70;
    stroke-width: 2px;
    fill: none;

    // years
    &.chart-line-average {
        stroke-dasharray: 3 2;
        opacity: .65;
    }
    &.chart-line-this-year {

    }

    // aspects
    &.chart-line-rainfall {
        stroke: @blue;
    }
    &.chart-line-gdd {
        stroke: @red;
    }
}


.chart-tooltip-hoverline {
    stroke-dasharray: 1 1;
    fill: white;
    pointer-events: none;
    stroke-width: 2px;
}



// CLICK AND DRAG RANGE

// the number in the box that shows the total in that range
.chart-range-value {

    position: absolute;
    top: 140px;
    left: 0;

    width: 50px;
    margin: 0 0 0 -15px;

    line-height: 20px;
    border-radius: 6px;
    background: white;

    font-weight: 500;
    font-size: @font-size-m;
    text-align: center;

    z-index: 100;

    // colors
    &.chart-range-value-gdd {
        color: @red;
    }
    &.chart-range-value-rainfall {
        color: @blue;
    }

    // elements
    .date {
        font-size: @font-size-s;
        font-weight: 500;
        color: @text-color-secondary;
    }
    .main {
        font-size: @font-size-xl;
        font-weight: 500;
    }
    .secondary {
        font-size: @font-size-s;
        font-weight: 500;
    }

}

// the range box that appears when dragging
.brush .extent {
    fill: @smoke-60;
    fill-opacity: .2 !important;
}



// BOX ON HOVER

// box that appears on hover
.chart-hover-box {
    position: absolute;
    top: -50px;
    z-index: 100;
    background: white;
    border: 1px solid @smoke-80;
    box-shadow: 0 2px 4px @clear-smoke-05;
    padding: .5em 1em;
    border-radius: 2px;
    min-width: 120px;
    margin-left: 10px;
    pointer-events: none;

    .date {
        font-size: @font-size-s;
        font-weight: 500;
        color: @text-color-secondary;
    }
    .main {
        font-size: @font-size-xl;
        font-weight: 500;
    }
    .secondary {
        font-size: @font-size-s;
        font-weight: 500;
    }

    &.chart-hover-box-gdd {
        color: @red;
    }
    &.chart-hover-box-rainfall {
        color: @blue;
    }
}

// line under that box
.chart-tooltip-hoverline {
    stroke: @text-color-primary !important;
    stroke-dasharray: 0 !important;
    stroke-opacity: .5;
    stroke-width: 1px !important;
}


// PLANTING AND HARVESTING DATE LINES

.chart-activity-line {
    stroke: @text-color-secondary;
    stroke-dasharray: 6 3;
    stroke-opacity: .5;
    stroke-width: 1px;
}

.chart-activity-label {
    .small-caps-heading();
    fill: @text-color-secondary;
}


// LEGENDS

.legend {
    float: right;
    margin: 6px 0;

    &.legend-bottom-right {
        position: absolute;
        right: 2em;
        bottom: 3em;

        // put it "above" the chart lines
        background: inherit;
        padding: .5em 0;
        z-index: 1;
    }

    .legend-item {

        float: left;
        margin: 0 0 0 1em;

        font-weight: 500;
        color: @text-color-secondary;
        font-size: @font-size-s;
        line-height: 1;

        svg {
            margin: 0 1em;
        }

        .indicator {
            float: left;
            width: 24px;

            &.amount-this-year {
                background: @blue;
                height: 3px;
                margin: 4px .5em 5px;
            }
            &.amount-average {
                background: @light-blue;
                height: 8px;
                margin: 2px .5em;
                opacity: .2;
            }
        }
    }
}


// HEAT MAP LEGEND

.heat-map-legend {

    .gradient {
        width: 100%;
        height: 14px;
        margin: 0 0 1em;
    }

    .numbers {
        position: relative;
        overflow: auto;
        margin: 0 0 .5em;

        .number {
            font-weight: 500;
            font-size: @font-size-s;
            color: @text-color-primary;
            text-align: center;
            width: 6%;
            margin-right: 12.5%;
            float: left;

            &:last-child {
                margin-right: 0;
                float: right;
            }
        }
    }
}

// CHART ATTRIBUTION

.attribution {
    font-size: @font-size-xs;
    color: @smoke-70;
    font-style: italic;
    font-weight: 500;
    margin: -2em 0 2em;
}
