/*
*	RAINFALL BADGE
*
*	Styles for little pill-style badge that shows a rainfall number in inches
*
*/

.badge {
    display: inline-block;
    border-radius: 2px;
    color: white;
    font-size: @font-size-m;
    font-weight: 500;
    line-height: 1;
    text-shadow: none;
    padding: 6px 8px;

    &.recent-rainfall-badge {
        background: @blue;
        margin-right: .4em !important;
        &.no-rain {
            background: @smoke-92;
            color: @text-color-secondary;
        }
    }

    &.heat-stress-badge {
        h1 & {
          margin-right: 5px;
        }
        background: @red;
        letter-spacing: .5px;
        text-transform: uppercase;
    }

    &.preview-badge {
      background: @blue;
    }
}

.recent-rainfall-text {
  display: inline;
  color: #737b8c;
  font-weight: 500;
  font-size: 11px;

  &.rainfall-block {
    display: block;
    margin-left: 0;
  }

}

.heat-stress-badge {
    text-transform: uppercase;
    letter-spacing: .5px;
    font-size: @font-size-s;
    background: @red;
}

// when inline in tables
table .recent-rainfall-badge-wrapper {
    margin: -2px 0 -10px 0;
}

// in the field page header
h1 .recent-rainfall-badge-wrapper {
    margin: 0 0 0 1em;
}

.rainfall-tour-notification {
  display: inline-block;
  color: @blue;
  font-size: 13px;
  border-radius: 3px;
  border: 1px solid @blue;
  padding: 7px;
  margin-left: 10px;
  a {
    color: @blue;
    text-decoration: underline;
    margin-right: 3em;
    vertical-align: text-top;
  }
  span {
    vertical-align: text-top;
  }
  .rainfall-tour-notification-message {
    padding-left: .5em;
  }
  .close-x {
    position: relative;
    vertical-align: baseline;
    right: 5px;
    font-size: 20px;
  }
}

.rainfall-tour-header {
  background-color: @blue;
  padding: 1.5em;

  span {
    color: white !important;
  }
}

.rainfall-tour-body {
  font-size: 16px;
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  ul {
    display: table;
    margin: 0 auto;
  }
  li {
    list-style-image: url("../../../assets/images/fields/drop.png");
    text-align: left;
    margin-top: 15px;
  }
  .btn {
    color: @blue;
    &.rainfall-tour-button {
      padding: .5em 3em;
      font-size: 16px;
      background-color: #FAFAFB;
      color: #D2D5D9;
      span {
        color: #267FD9;
      }
    }
  }
}

.rainfall-indicator-wrapper {
  display: inline-block;

  &.fl-tooltip:hover {
    &:before {
      top: initial;
      bottom: -4px;
      transform: rotate(180deg);
    }
    &:after {
      line-height: 18px;
      top: 100%;
      margin-top: 4px;
      white-space: normal;
      width: 200px;
      padding: .75em;
    }
  }
}

.rainfall-tour-modal {
  top: 5%;
}
