/*
*   BIG STATS
*
*   Styles for big summary statistics
*
*/


// BIG STATS

.big-stats {
    padding: 0 0 0 0;
    margin: 0 0 2em;
    text-align: center;

    &.vertical {
        text-align: left;
        margin: 2em 0 0;

        .big-num {
            text-align: left;
        }
        .big-num-desc {
            text-align: left;
            margin-bottom: 14px;
        }
    }

    .big-num {
        font-size: @font-size-xl;
        line-height: 1;
        color: @text-color-primary;
        position:relative;
        text-align: center;
        margin: 0px 0 0;

        &.up::after {
            .before-after();
            left:-28px;
            top:24px;
            border-style:solid;
            border-color:hsl(120,50%,60%) transparent;
            border-width:0 10px 10px 10px;
        }
        &.down::after {
            .before-after();
            left:-28px;
            top:24px;
            border-style:solid;
            border-color:hsl(10,70%,60%) transparent;
            border-width:10px 10px 0 10px;
        }

        &.positive {
            color: @green;
        }

        &.negative {
            color: @red;
        }

        .unit {
            font-size: @font-size-l;
            color: @text-color-secondary;
            margin: 0 0 0 .3em;
            font-weight: 500;
            display: inline-block;
            letter-spacing: 0px;
        }
    }

    .big-num-desc {
        text-align: center;
        color: @text-color-secondary;
        text-transform: uppercase;
        letter-spacing: .05em;
        font-weight: 500;
        font-size: @font-size-xs;
        margin: 0 0 4px;
        display: inline-block;
    }
}

