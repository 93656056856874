/*
*	LAYOUT
*
*	Global layout for the app. Header, main nav, footer, etc
*
*/

@header-height: 50px;
@sidebar-width: 190px;
@maximum-layout-width: 1250px;

*,
*:before,
*:after {
  // fix box model
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// HTML & BODY

html,
body {
  height: 100%;
  background: white;
}

#layout {
  background-color: @white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#appContent {
  min-height: 100vh;
  overflow-y: scroll;
}

// HEADER

#header {
  background: #2d3748;
  padding-left: 15px;
  transition: top .5s ease;
  -webkit-transition: top .5s ease;
  z-index: 2000;
  .box-shadow(0 2px 2px @clear-smoke-10);

  .header-nav {
    position: absolute;
    left: 160px;
    top: 0;

    li {
      display: inline-block;

      a {
        display: block;
        color: white;
        font-weight: bold;
        font-size: 15px;
        text-shadow: 0 1px 1px darken(@green, 10%);
        padding: 16px 10px;
        margin: -1px 0;

        &:hover {
          background: darken(@green, 3%);
          text-decoration: none;
        }
      }

      .selected {
        border-bottom: 2px white solid;
        height: 51px;
      }
    }
  }

  .help-link {
    .ui-control();
    float: right;
    font-weight: bold;
    padding: 16px;
    line-height: 17px;
    color: white;
    text-shadow: 0 1px 1px darken(@green, 10%);

    &:hover {
      background: darken(@green, 3%);
      text-decoration: none;
    }
  }

  .user-info {
    .ui-control();
    float: right;
    color: white;
    font-weight: bold;
    text-shadow: 0 1px 1px darken(@green, 10%);
    margin: 0;
    padding: 16px 32px 16px 16px;
    line-height: 17px;
    position: relative;

    &:hover {
      background: darken(@green, 3%);
    }

    &:after {
      .before-after();
      pointer-events: none;
      border-width: 4px 4px 0;
      border-style: solid;
      border-color: white transparent;
      top: 50%;
      margin: -2px 0 0;
      right: 16px;
    }
  }
}


// MAIN APP WRAPPER
#main {
  padding-top: @header-height;
  z-index: 1; //keep map elements from overlaying the header dropdown
  transition: padding 2ms ease;

  // ANALYTICS PAGE
  // the white thing that wraps all report-style pages
  .analytics-page {
    padding: 40px 50px 60px;
    min-height: 100%;

    // headline at the top of the page
    .headline {
      border-bottom: 1px solid @separator-color;
      margin-bottom: 2em;
      padding: 0 0 2rem;

      .sub-headline {
        margin: 10px 0 -20px;
      }
    }
  }

  // SIDEBARS

  .analytics-sidebar {
    border-left: 1px solid @separator-color;
    position: relative;
  }

  // fluid sidebar for field detail
  .analytics-sidebar-field-content {
    min-height: 1px; // keeps it from collapsing if there's nothing there
    padding-left: 0px;
  }
  .analytics-sidebar-field {
    position: relative;
    margin-top: -6rem;

    .sidebar-field-widget {
      padding-bottom: 20px;
      border-bottom: 1px solid #e3e5e8;

      .no-crops {
        margin-top: 10px;
      }
    }
  }

  .analytics-sidebar-detail {
    margin-left: 4%;
    padding-left: 20px;
    position: relative;
  }
}

// point field overlay on field detail
.point-overlay-field-detail {
  position: absolute;
  width: 100%;
  height: 99%;
  background: @clear-smoke-80;
  text-align: center;

  h1 {
    color: white;
    padding-top: 36%;
  }
}

// FOR LOGIN, REGISTRATION, ETC FORMS

#main-external {
  background: @smoke-94;

  a {
    text-decoration: underline;
    font-weight: 400;
  }
}

.login-box {
  width: 260px;
  margin: 7em auto 1em;
  border-radius: 2px;
  background: white;
  box-shadow: 0 3px 5px hsla(0, 0%, 0%, .1);

  .error {
    color: white;
    font-weight: bold;
    padding: 20px;
    background: @red;

    a {
      color: white;
    }
  }

  .header {
    padding: 20px;
    border-bottom: 1px solid @separator-color;

    h2 {
      margin: .25em 0 0 0;
      font-size: @font-size-l;
      font-weight: bold;
      color: @text-color-primary;
      text-align: center;
    }

    h3 {
      line-height: 1;
      margin: 0 0 1em;
      font-size: @font-size-l;
      font-weight: bold;
      color: @green;
    }
    p {
      margin: 0;
    }
  }

  .main {
    padding: 2em;
  }

  .footer {
    p {
      margin: 10px 0 0;
      font-weight: 400;

      a {
        color: @text-color-primary;
      }
    }
  }
}

// RESOURCE ICONS
// pictures of tractors, fields etc in the sidebar on detail pages
.resource-icon-wrapper {
  overflow: visible;
  border: 3px solid @separator-color;
  position: relative;
  display: block;
  margin: 0 0 2em;

  &.no-border,
  &.no-border:hover {
    border: none;
  }

  &:hover {
    border-color: @smoke-90;
  }

  img {
    width: 100%;
    height: auto;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.section {
  margin: 2em auto;
}

.spaced {
  margin: 1em auto;
}

// NARROW PAGES
// TODO: figure out what the deal is here and rename stuff better

.page-wrapper-larger {
  max-width: 800px;
  margin: 1em auto;
}

.page-wrapper-largest {
  max-width: 920px;
  margin: 1em auto;
}

.narrow-page-wrapper {
  max-width: 768px;
  margin: 0 auto 1em auto;

  p a {
    text-decoration: underline;
  }
}

.medium-page-wrapper {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

/*
*	NARROW WIDTH
*/

@media (max-width: @screen-md-min) {
  #main {
    .analytics-sidebar-content,
    .analytics-sidebar,
    .analytics-sidebar-field-content,
    .analytics-sidebar-field,
    .analytics-sidebar-detail-content,
    .analytics-sidebar-detail {
      border: none;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

/*
*	PRINT STYLES
*/

@media print {
  // GLOBAL
  * {
    color: #5c6370;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  // things that wrap everything
  // includes fixes for IE print styles (pages were getting truncated)
  html,
  body,
  #main,
  .analytics-page {
    position: static;
    overflow: visible;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }

  .analytics-header {
    background: transparent !important;
  }

  .resource-icon-wrapper {
    svg {
      width: 100%;
    }
  }

  // LAYOUT
  #main {
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    overflow: visible;
  }

  // HIDDEN
  #header,
  #nav,
  #account-menu,
  #main .analytics-sidebar {
    display: none !important;
  }

  // PAGE WRAPPERS
  .page-wrapper-larger,
  .narrow-page-wrapper {
    max-width: none;
  }
}

@media only screen and (min-width: @screen-md-min) {
  #main .analytics-sidebar {
    min-height: 600px;
  }
}

@media only screen and (max-width: @screen-md-min) {
  #header .header-nav {
    left: 50px;
  }

  #nav {
    width: inherit;
    left: 0;
    bottom: 0;
    right: 0;
    top: 48px;
    padding: 15px 50px;
  }

  #main {
    .analytics-sidebar {
      border-bottom: 1px solid @separator-color;
      padding-bottom: 15px;
    }

    .analytics-sidebar-content {
      margin-top: 27px;
    }

    .analytics-sidebar-field {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: @screen-sm-min) {
  #header .header-nav {
    left: 15px;
  }

  #nav {
    padding: 15px;
  }

  #main .analytics-page {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }
}
