/*
*	ACTIVITIES
*
*	Styles for logging activities
*
*/

// ACTIVITY HEADER
// styles for activity type, field, and crop header

.activity-type {
    margin: 0.6em 0;

    h2 {
        margin: .1em 0;

        &:hover {
            color: @smoke-30;
        }
    }

    &.header {
        border: 1px solid @smoke-80;
        border-radius: 3px;
        text-align: center;
        padding: 20px 0px;
        min-height: 90px;
        cursor: pointer;

        &:hover {
            border-color: @smoke-60;
        }

        .activity-type-icon {
            margin-right: auto;
            margin-left: auto;
        }

        .activity-type-name {
            font-size: @font-size-l;
            padding-top: 10px;
            font-weight: 500;
        }
    }
}

.activity-detail-header {

    // headline and list of activities when manually logging
    .placeholder {
        color: @text-color-tertiary;
        font-size: @font-size-xxl;
        font-weight: 500;
    }

    .btn[disabled] {
      background-color: transparent !important;
      border: none !important;
      color: @text-color-primary !important;
      cursor: default;
    }

    // dropdown position
    .open {
        top: 48px;
        left: 0px;

        .icon {
            float: left;
            margin-right: 0.5em;
        }

        .dropdown-option-content {
          line-height: 27px;
        }
    }

    .field-header {

      .dropdown-option-content {
        line-height: 20px;
      }
    }

    .field-chooser {
      margin-bottom: -.4em;
    }

    // position activity type and field buttons
    .activity-headline {
        display: inline;
        max-width: 514px;
        text-overflow: ellipsis;
    }

    .activity-headline-field {
        display: inline-block;
    }

    .at {
        color: @text-color-secondary;
        font-size: @font-size-xl;
        position: relative;
        margin: 0 .3em;
    }

    .activity-crop, .activity-type {
        padding: .8em 0 .6em 0;
    }

    .activity-crop {
        border-top: 1px solid @smoke-90;
        border-bottom: 1px solid @smoke-90;

        margin: 1em 0 0;

        // temporary hack positioning until we build crop picker
        // with badge nested within

        .crop-name {
            padding: .6em .5em .6em 3em;
            font-size: @font-size-l;
            position: relative;
            left: -37px;
            font-weight: 500;
        }

        .map-badge {
            cursor: default;
            position: relative;
        }

        .badge-wrapper {
            margin-top: 6px;
            margin-left: 6px;
        }
    }

    // the triangle pointer
    .pointer-container {

      .pointer {
        position: absolute;
        display: block;

        width: 0;
        height: 0;

        background: white;
        border-color: transparent;
        border-style: solid;
        border-width: 11px;
        margin-top: 3.70em;
        border-top-width: 0;
        border-bottom-color: @smoke-80;
        top: -10px;

        transition: left 0.2s ease-out;

        &:after {
          .before-after();
          border-color: transparent;
          border-style: solid;

          border-width: 10px;

          top: 1px;
          margin-left: -10px;
          border-top-width: 0;
          border-bottom-color: white;
        }
      }

      hr {
        margin: 2em 0 0 0;
      }
    }

}

.activity-control-buttons {
  text-align: center;
  margin: 10px 0;
}

.activity-logger {
    .centered-link {
      width: 100%;
      padding: 1em;
      display: block;
      text-decoration: underline;
      text-align: center;
    }
}

// ACTIVITY DETAILS
// main body of activity detail

.activity-body {

    .wide-attribute {
        padding: 1.5em 0;
        border-bottom: 1px solid @separator-color;
    }

    .control-group {

        &.activity-tilling-rate {
            padding: 0 0 1.8em 0;
            border-bottom: 1px solid @separator-color;

            .control-label {
                width: auto;
            }

            .controls {
                margin-left: 50px;
            }
        }

        &.notes {
            margin-bottom: 0;

            textarea {
                max-width: none;
                min-height: 120px;
            }
        }

        &.images {
            margin-bottom: 2em;
        }

        &.weather {
            background-color: @smoke-98;
            border-bottom: 1px solid @separator-color;
            padding: 1.2em 1.8em;

            label {
                width: 90px;
                font-weight: 500;
                font-size: @font-size-s;
                color: @text-color-secondary;
                text-align: left;
            }
        }

        &.advanced-options {
            padding: 0 0 1em 0;

            .controls {
                margin-left: 0px;
            }
        }
    }

    // target form styles for gray icon section
    .primary-details {
        border-bottom: 1px solid @separator-color;
        padding: 1.5em 0 .5em .5em;
        margin: 0 0 2em 0;

        input.datepicker {
            .buttony();
            .box-shadow(none);
            border: 1px solid transparent;
            font-size: @font-size-m;
            line-height: 1.2;
            padding: .5em 1em;
            background: white;
            text-align: left;

            &:hover {
                cursor: pointer;
                border: 1px solid @smoke-90;
                background: none;
            }
        }

        .entity-name {
            padding: 0.5em 1em;
            line-height: 2.5;
            color: @text-color-primary;
            font-weight: 500;
        }

    }

}

// Save
// activity detail sidebar styles

.type-filter {
    margin-bottom: 1em;
}

.activity-footer {
    margin-left: 0px;
    text-align: center;
    padding-top: 1em;

    .controls {
        margin-left: 0px;
    }

    .save {
        margin-bottom: 1.5em;
    }

    .cancel {
        margin-left: .5em;
    }

}

@media print {

    .at {
        top: 0px!important;

    }

    .activity-crop {
        padding: 14px 0px!important;

        .map-badge {
            display: none;
        }
    }

}

@media only screen and (max-width: @screen-md-min) {
    .activity-detail-header {
        padding-left: 2em;
    }
}
