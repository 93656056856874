/*
*   YEAR PICKER
*
*   Styles for big year picker w/ dropdown
*   NOTE: these classes apply to both the map AND report-style pages like #fields
*   See map styles for map-specific year-picker
*
*/


.year-picker {
    max-height: 31px;
    position: relative; // contain dropdown

    @media only screen and (max-width: 37.5rem) {
        background: white;
    }

    // BUTTON
    // wraps the three buttons of the year picker: the year, next, and prev

    .year-picker-button {
        display: inline-block;

        .nav,
        .year {
            .buttony();
            .ui-control();
            line-height: 1;
            float: left;
            padding: 0.5em;
            color: @text-color-primary;
            z-index: 20;
            font-size: @font-size-xl;

            @media only screen and (max-width: 37.5rem) {
                font-size: @font-size-l !important;
            }
        }
        .nav {
            font-weight: 300;

            &.left {
                left: 0;
                border-right: 1px solid @smoke-80;
                border-radius: 3px 0 0 3px;
            }
            &.right {
                right: 0;
                border-left: 1px solid @smoke-80;
                border-radius: 0 3px 3px 0;
            }
            // disabled nav arrows aren't clickable
            &.disabled {
                background: #f5f5f5;
                color: @text-color-secondary;
                cursor: auto;
                &:hover {
                    background: #f5f5f5;
                    color: @text-color-secondary;
                }
            }
        }
        .year {
            font-weight: 400;
            border-radius: 0;
            margin: 0 -1px;

            // disabled year pickers *are* clickable
            &.disabled {
                background: #f5f5f5;
                color: @text-color-secondary;
                &:hover {
                    background: #f9f9f9;
                    color: @text-color-secondary;
                }
            }
        }
    }


    // DROPDOWN
    // list of years to choose from

    .year-picker-dropdown {
        display: none;
        position: absolute;
        top: 37px;
        width: 80%;
        left: 10%;
        border: 1px solid @smoke-80;
        .box-shadow(0 2px 4px rgba(0,0,0,0.2) );
        z-index: 200;
        max-height: 300px;
        overflow-y: auto;

        .year {
            padding: 0.5em;
            background: @white;
            cursor: pointer;
            color: @text-color-primary;
            float: right;
            width: 100%;
            border-bottom: 1px solid @smoke-80;
            font-weight: 400;
            font-size: @font-size-xl;
            text-align: center;

            &:active {
                background: #fafafa;
            }
            &:hover {
                color: @smoke-20;
            }
            &:last-child {
                border: none;
            }
            &.disabled {
                background: #f5f5f5;
                color: @text-color-secondary;

                &:hover {
                    background: #f9f9f9;
                    color: @text-color-secondary;
                }
            }

            @media only screen and (max-width: 37.5rem) {
                font-size: @font-size-l;
            }
        }
    } // end year picker dropdown

} // end year picker



// thing based off the year picker for imagery
// maybe year picker should extend this in the future?

.multi-picker {
    position: relative; // contain dropdown
    width: 100%;
    height: 2.7em;
    display: block;

    .fl-dropdown {
      top: 34px;
      left: 31px;
      width: 311px;
    }


    // BUTTON
    // wraps the three buttons of the year picker: the buttons

    .multi-picker-button {
        display: inline-block;

        .multi-picker-nav,
        .multi-picker-value {
            .buttony();
            .ui-control();
            line-height: 1;
            padding: 0.5em;
            color: @text-color-primary;
            z-index: 20;
            font-size: @font-size-l;
            position: absolute;
            top: 0;
        }
        .multi-picker-nav {
            font-weight: 400;
            width: 2em;

            svg {
                width: 24px;
                height: 24px;
                margin: -7px;

                path {
                    fill: @smoke-40;
                }
            }

            &.left {
                left: 0;
                border-right: 1px solid @smoke-80;
                border-radius: 3px 0 0 3px;

                svg {
                    .transform(rotate(-180deg));
                }
            }
            &.right {
                right: 0;
                border-left: 1px solid @smoke-80;
                border-radius: 0 3px 3px 0;
            }
        }
        .multi-picker-value {
            font-weight: 500;
            border-radius: 0;
            margin: 0 -1px;
            left: 2em;
            right: 2em;
        }
    }

} // end multi picker



@media print {
    .year-picker {
        display: none;
    }
}
