.clickable {
  cursor: pointer;
}

.card {
  box-shadow: 0 2px 2px rgba(46, 49, 56, 0.1);
  border-radius: 6px;
  border: 1px solid @smoke-90;
  background: @white;
  padding: 1em;

  &.card-padded {
    .card-body {
      margin: 0 1.5em;
      padding: 1em .5em;
    }

    .card-footer {
      padding: 2em 1.5em;
    }
  }

  .card-body {
    .clear-fix();
    background-color: transparent;
    position: relative;

    &.collapsed {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      padding: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid @smoke-90;
    }
  }

  .card-footer {
    margin-top: -1px;
    padding: 2em 2em;
    background-color: transparent;
    border-top: 1px solid @smoke-90;
  }

  & > .card-body:last-child {
    border-bottom: none;
  }

  .error-message {
    margin-top: 1em;
  }

  .bottom {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    font-weight: 500;

    .label {
      text-transform: uppercase;
      font-weight: 500;
    }

    .label, .date {
      color: @smoke-60;
    }
  }

  &.rx-input-card {
    max-width: 300px;

    .card-body {
      height: 140px;
    }
  }
}


//Need card footer
//make margin more custizable from background fills
