/*
*	MIXINS
*
*	Custom Bushel Farm mixins used in other styles
*
*/


// CLEAR FIX
// a mixin to keep parent element height from collapsing when all
// its children are floated. useful for grid rows, tabs, pills, etc.
// http://fuseinteractive.ca/blog/understanding-humble-clearfix

.clear-fix() {

	&::after {
		content: '';
		display: block;
		clear: both;
	}
}


// BEFORE & AFTER
// a convenient mixin to use on all :before and :after pseudo elements
// that you want to appear as block-style elements
// ex: ribbons, pointers, checkboxes

.before-after() {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
}


// UI CONTROL
// an abstract mixin to style "things that are clicked"
// specifically things that aren't text
// useful for buttons, nav items, etc

.ui-control() {

	// prevent accidental text highlighting
	user-select: none;

	// make it look clickable
	cursor: pointer;

	// don't show underlines on anchor tags
	text-decoration: none;
	&:hover { text-decoration: none; }

    // prevent browser focus outlines
    outline: none;
    &:focus {
        outline: none;
    }

}


// BOX SHADOW
// simple browser prefix added for box shadows

.box-shadow(@args) { }


// GRADIENT
// basic linear top-to-bottom gradient

.gradient(@default, @start, @stop) {
    background-color: @default;
}


// TRANSITION
// vendor-prefix for transitions

.transition(@args) {
    transition: @args;
}

// TRANSFORM
// vendor-prefix for transforms
.transform(@args) {
    transform: @args;
}

// TRANSFORMS
// vendor-prefix for transforms

.transform(@args) {
    transform: @args;
}


// ANIMATION
// vendor-prefix for animations

.animation(@args) {
    animation: @args;
}

// BUTTONY
// a mixin to make any element look like a link
// used in dropdown-option

.linkify() {
  color: @blue;
  font-weight: 500;
  cursor: pointer;

  &:hover,
  &:active {
      color: darken(@blue, 10%);
  }

  &.disabled,
  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.75;
  }
}

.link {
  .linkify();
}


// BUTTONY
// a mixin to make any element look like a button
// used in buttons, obv, plus selects, etc

.buttony() {
    .ui-control();

    padding: 0.438rem 1rem;
    position: relative;
    line-height: 1.5rem;
    text-align: center;

    color: @text-color-primary;
    font-weight: 500;
    font-size: @font-size-m;

    border: 1px solid @smoke-80;
    border-radius: .25rem;

    text-decoration: none; // anchors with button class

    .box-shadow(0 2px 2px @clear-smoke-05);
    .gradient( @smoke-98, @white, @smoke-95 );

    &, a, &:hover, a:hover {
			text-decoration: none; // anchors with button class
		}

    &:hover {
        color: @text-color-primary;
        .gradient( @smoke-98 , @white , @smoke-97 );
    }

    &:active {
        .gradient( @smoke-95 , @smoke-95 , @smoke-94 );
    }

    &:focus {
        background-position: 0 0;
        outline: none;
        border: 1px solid @smoke-60;
    }

    &.disabled,
    &:disabled,
    &[disabled] {
      cursor: default;
      pointer-events: none;
      opacity: 0.75;
    }
}

.bottom-right-arrow(@size, @color) {
  &:before {
    content: none;
    border: none !important;
  }
  &:after {
    position: absolute;
    content: '';
    top: 90%;
    right: 0;
    border-left: @size solid transparent;
    border-top: @size+1 solid @color;
  }
}

.bottom-left-arrow(@size, @color) {
  &:before {
    content: none;
    border: none !important;
  }
  &:after {
    position: absolute;
    content: '';
    top: 90%;
    left: 0;
    border-right: @size solid transparent;
    border-top: @size+1 solid @color;
  }
}

// ARROW
// Arrow for tooltips, dropdowns, etc...

// Basic usage:
// .basic-arrow-example {
//   .arrow(8px, inherit, right, 50%);
// }
//
// .more-complex-arrow-example {
//   border: 2px solid darken(#08C, 10%);
//   .arrow(20px, #08C, bottom, 25%, 2px, darken(#08C, 10%));
// }
// Demo: http://codepen.io/ndonohue/pen/zvJLBd

.arrow(
  @size,
  @color,
  @direction,
  @offset,
  @border-size: 0,
  @border-color: inherit) {
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
  }
}

.arrow(@size, @color, @direction, @offset, @border-size: 0, @border-color: inherit) when (@direction = top) {
  @m-size: @size + (@border-size*2);

  &:after {
    bottom: 100%;
    left: @offset;
    margin-left: -@size;

    border-left: @size solid transparent;
    border-right: @size solid transparent;
    border-bottom: @size solid @color;
  }

  &:before {
    bottom: 100%;
    left: @offset;
    margin-left: -@m-size;

    border-left: @m-size solid transparent;
    border-right: @m-size solid transparent;
    border-bottom: @m-size solid;
    border-bottom-color: @border-color;
  }
}

.arrow(@size, @color, @direction, @offset, @border-size: 0, @border-color: inherit) when (@direction = bottom) {
  @m-size: @size + (@border-size*2);

  &:after {
    top: 100%;
    left: @offset;
    margin-left: -@size;

    border-left: @size solid transparent;
    border-right: @size solid transparent;
    border-top: @size solid @color;
  }

  &:before {
    top: 100%;
    left: @offset;
    margin-left: -@m-size;
    border-left: @m-size solid transparent;
    border-right: @m-size solid transparent;
    border-top: @m-size solid;
    border-top-color: @border-color;
  }
}

.arrow(@size, @color, @direction, @offset, @border-size: 0, @border-color: inherit) when (@direction = right) {
  @m-size: @size + (@border-size*2);

  &:after {
    left: 100%;
    top: @offset;
    margin-top: -@size;

    border-top: @size solid transparent;
    border-bottom: @size solid transparent;
    border-left: @size solid @color;
  }
  &:before {
    left: 100%;
    top: @offset;
    margin-top: -@m-size;

    border-top: @m-size solid transparent;
    border-bottom: @m-size solid transparent;
    border-left: @m-size solid;
    border-left-color: @border-color;
  }
}

.arrow(@size, @color, @direction, @offset, @border-size: 0, @border-color: inherit) when (@direction = left) {
  @m-size: @size + (@border-size*2);

  &:after {
    right: 100%;
    top: @offset;
    margin-top: -@size;

    border-top: @size solid transparent;
    border-bottom: @size solid transparent;
    border-right: @size solid @color;
  }
  &:before {
    right: 100%;
    top: @offset;
    margin-top: -@m-size;

    border-top: @m-size solid transparent;
    border-bottom: @m-size solid transparent;
    border-right: @m-size solid;
    border-right-color: @border-color;
  }
}
