.separator {
  border-bottom: 1px solid @separator-color;
}

.product-chooser {
  overflow: scroll;

  .blank-slate {
    .separator();
    height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

	.result-header {
    .separator();
    margin-top: 1em;
		color: @text-color-secondary;
		text-transform: uppercase;
		font-size: @font-size-s;
		font-weight: bold;
    padding-bottom: 0.5em;
	}

  .result-option {
    .separator();
    padding: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;

    .result-content {
      flex: 1;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: @smoke-95;
      .choose {
        visibility: visible;
        color: @blue;
      }
    }

    .title {
      display: block;
      font-size: @font-size-l;
      font-weight: 500;
      line-height: 24px;
    }

    .sub {
      display: block;
  		color: @text-color-secondary;
  		font-size: @font-size-m;
    }

     img.icon-verified {
      width: 13px;
      margin-left: 0.5em;

    }

    // hack to get tooltips displaying OK without breaking them elsewhere
    .basic-tooltip.border-caret.caret-bottom {
      padding: 0;
      width: 230px;
      left: -80px;
      top: -80px;
    }

    .choose {
      visibility: hidden;
    }
  }
}
