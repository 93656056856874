/*
* SUMMARY BAR
*
*
*/
.sidebar-pending {
  .card {
    height: 350px;
    padding-top: 160px;
  }

  .loading-spinner-only:before {
    margin: 0 0 0 0;
  }
}

.accordion {
  width: 100%;

  .label {
    text-transform: uppercase;
    color: @text-color-secondary;
    font-weight: 500;
    font-size: 12px;
  }

  .error {
    margin-top: .5em;
  }

  .unit {
    margin-left: .3em;
    font-size: 13px;
    color: @smoke-40;
  }

  .nitrogen-planting-warnings {
    text-align: center;
    background: @smoke-96;
    border-radius: 2px;
    font-size: @font-size-m;
    padding:15px;
    margin: 15px -5px -5px -5px;

    .btn-primary {
      margin-top: .5em
    }
  }

  .card.card-padded {
    margin: 0;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid @smoke-80;

    .loading-spinner {
      margin: 1em;
    }

    .accordion-section {
      &:last-child {
        .card-body {
          border: none !important;
        }
      }
    }

    .card-body {
      margin: 0;
      padding: 2rem 1.75rem;
      border-color: @smoke-90;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.25s ease 0.75s;// border color show

      &.has-amount.green-text {
        color:#3A9E53
      }

      &.has-amount.red-text {
        color:#CE4B2C;
      }

      &.expanded {
        transition: all 0.25s ease; // border color hide
        border-color: transparent !important;
      }

      .card-content {
          display: inline-block;
          width: 100%;
          text-align: left;
      }

      .expand-icon {
        cursor: pointer;

        img {
          width: 20px;
        }
      }
    }

    .expanded-container {
      overflow: hidden;
      width: 100%;
      max-height: 0;

      .expanded-section {
        background: @clear-smoke-03;
        min-height: 60px;
        border-top: 1px solid #e3e5e8;
        border-bottom: 1px solid #e3e5e8;
        position: relative;
        box-shadow: inset 0px 3px 4px -3px rgba(0,0,0,.15);

        &.pointer {
          &:after {
            transition: all .25s ease 0.5s; // pointer hide
            position: absolute;
            width:  10px;
            height: 10px;
            display: block;
            background: white;
            box-shadow: 0px 0px 1px 1px rgba(0,0,0,.1);
            top: -5px - 1;
            left: 35px;
            content: ' ';
            transform: rotate(45deg) scale(0);
          }
        }

        .expanded-body {
          margin: 20px;
        }
      }

      &.expanded {
        max-height: 100%;
        transition: all 1s cubic-bezier(0, 0, 0.50, 1);
        overflow: scroll;

        .expanded-section.pointer {
          &:after{
            transition: all 0.25s ease; // pointer show
            transform: rotate(45deg) scale(1);
          }
        }
      }
    }
  }

  .additional {
    &.no-pointer {
      &:after {
        border: none !important;
      }
      &:before {
        border: none !important;
      }
    }
    &:after {
      position: absolute;
      top: -2px;
      left: 35px;
      content: " ";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid white;
    }
    &:before {
      position: absolute;
      top: -1px;
      left: 35px;
      content: " ";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #ddd;
    }

    &.card-body.red-background {
      margin: 0;
      padding: 2em 1.5em;
      border-radius: 0 0 3px 3px;
      background-color:#CE4B2C;
      .unit, .amount, .label {
        color: white;
      }
    }

    &.red-text, &.red-text .label {
      color:#CE4B2C;
    }

    &.green-text, &.green-text .label {
      color:#3A9E53;
    }
  }

  a {
    display: inline-block;
    margin: 0;
  }
}
