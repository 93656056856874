/*
*	CLOSE X
*
*	Styles for little X's you click to close things
*
*/

.close-x {
	.ui-control();

	// undo button styles etc
	.box-shadow(none);
	border: none;
	background: none;
	margin: 0;
	padding: 0;

	// position
	position: absolute;

	// style the x
	font-weight: 400;
	line-height: .5;

	// default size
	font-size: 27px;
	top: 18px;
	right: 18px;

	// hover states
	opacity: .8;

	&:hover,
	&:focus,
	&:active {
		opacity: 1;

		// button overrides
		border: none;
		background: none;
		.box-shadow(none);
	}


	// POSITION
	// vertically center close-x in it's parent
	// requires parent to have position other than initial

	&.close-x-center {
		top: 50% !important; // important to overwrite size positions below (hacky i know)
		transform: translate(0, -50%);
	}


	// SIZES
	// large is the default (atm)
	&.close-x-xxl {
		font-size: 39px;
		top: 26px;
		right: 26px;
	}
	&.close-x-xl {
		font-size: 33px;
		top: 22px;
		right: 22px;
	}
	&.close-x-l {
		font-size: 27px;
		top: 18px;
		right: 18px;
	}
	&.close-x-m {
		font-size: 21px;
		top: 14px;
		right: 14px;
	}
	&.close-x-s {
		font-size: 18px;
		top: 12px;
		right: 12px;
	}
	&.close-x-xs {
		font-size: 15px;
		top: 10px;
		right: 10px;
	}


	// SPECIAL

	// light-box style

	&.close-x-outside {
		width: 1.5em;
		height: 1.5em;
		line-height: 1.4em;
		right: -.75em;
		top: -.75em;
		opacity: 1;
		background: white;
		color: @text-color-primary;
		box-shadow: 0 2px 4px @clear-smoke-20;

		text-align: center;
		border-radius: 100px;

	}

	// transparent bg style
	// used on note detail

	&.close-x-overlay {
		color: @white;
		background-color: @clear-smoke-90;
		top: 0;
		right: 0;
		padding: 6px 6px 10px 6px;
	}

}


@media only screen and (max-width: @screen-md-min) {
	.close-x.close-x-outside {
		top: .5em;
		right: .5em;
	}
}
