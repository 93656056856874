/*
* DROP MENU
*
* Styles for dropdowns
*
*/


.fl-dropdown {

  // position
  position: absolute;
  z-index: 200; // keep above '.add-crop' button on field index mobile view
  top: 40px;

  // size
  height: 0; // closed by default
  opacity: 0;
  width: 200px;
  min-height: 20px;

  // style
  border-radius: 3px;
  border: 1px solid @smoke-80;
  box-shadow: 0 5px 20px @clear-smoke-10;
  background: white;

  // hidden by default
  display: none;

  &.dropdown-600 { width: 600px; }
  &.dropdown-500 { width: 500px; }
  &.dropdown-400 { width: 400px; }
  &.dropdown-300 { width: 300px; }

  &.inverted {
    background: @clear-smoke-95;
    border: 1px solid lighten(@clear-smoke-95, 10%);

    .fl-dropdown-option {

      border-bottom: 1px solid lighten(@clear-smoke-95, 10%);

      &:hover:not(.no-click) {
        background: lighten(@clear-smoke-95, 5%);
      }

      .sub {
        color: @smoke-80;
      }

      .title {
        color: @smoke-95;
      }
    }

    .fl-dropdown-pointer {
      border-bottom-color: lighten(@clear-smoke-95, 10%);

      &:after {
        border-bottom-color: @clear-smoke-80;
      }
    }
  }

  // STATES

  &.open,
  .fl-dropdown-option-wrapper {
    height: auto;
    max-height: 400px;
    min-height: 30px;
    opacity: 1;
    display: block;
  }

  .fl-dropdown-option-wrapper {
    overflow: auto;
  }


  // POSITION

  &.fl-dropdown-left {
    left: -2px;

    .fl-dropdown-pointer {
      left: 14px;
    }
  }

  &.fl-dropdown-right {
    right: -1em;

    .fl-dropdown-pointer {
      right: 14px;
    }
  }


  // SUB ELEMENTS

  .dropdown-section-header {
    font-size: @font-size-m;
    padding: .3em 1em;
    background: @smoke-95;
  }

  // the list inside the dropdown
  .fl-dropdown-option {
    position: relative;
    font-size: @font-size-m;
    //font-weight: 400;
    font-weight: 500;
    padding: .8em 1em;
    border-bottom: 1px solid @separator-color;
    line-height: 1.2;
    text-align: left;
    display: flex;
    align-items: center;

    &:hover:not(.no-click) {
      background: @smoke-98;
      cursor: pointer;
    }

    .icon {
      margin: 0 10px 0 0;
      position: relative;

      img {
        width: 45px;
      }
      .anomaly-badge {
            position: absolute;
            left: 70%;
            top: 60%;
        }
    }

    .dropdown-option-content, .title {
      color: @text-color-primary;
      font-size: @font-size-l;
    }

    &.linkify {
      .linkify();

      .dropdown-option-content, .title {
        color: currentColor;
      }
    }

    .title {
      margin: 0 0 .5em;
    }

    .sub, p {
      color: @text-color-secondary;
      font-size: @font-size-m;
    }

    & > *:last-child {
      margin: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  // the triangle pointer
  .fl-dropdown-pointer {
    position: absolute;
    display: block;

    width: 0;
    height: 0;

    border-color: transparent;
    border-style: solid;
    border-width: 11px;

    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: @smoke-80;
    top: -11px;

    &:after {
      .before-after();
      border-color: transparent;
      border-style: solid;

      border-width: 10px;

      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
      border-bottom-color: white;
    }
  }
}
