/*
*	LOADING
*
*	Styles for loading states
*
*/


.loading-placeholder {
	text-align: center;
	margin: 160px auto;

}

// SPINNER

@spinner-size: @font-size-xl;

// wrapper
.loading-spinner {
	margin: 2em auto 4em;
	position: relative;
	height: @spinner-size;

	// actual spinner
	&:before {
		.before-after();
		width: @spinner-size;
		height: @spinner-size;
		left: 50%;
		margin: 0 0 0 @spinner-size * -1/2;

		border-radius: 100px;
		border-width: 2px;
		border-style: solid;
		border-color: @text-color-secondary transparent;

		animation: spinner 2.5s;
		-webkit-animation: spinner 2.5s;
		animation-timing-function: ease;
		-webkit-animation-timing-function: ease;
		animation-iteration-count: infinite;
		-webkit-animation-iteration-count: infinite;
	}
}

// wrapper
.loading-spinner-only {

	// actual spinner
	&:before {
		.before-after();
		width: @spinner-size;
		height: @spinner-size;
		left: 50%;
		margin: 0 0 0 @spinner-size * -1/2;

		border-radius: 100px;
		border-width: 2px;
		border-style: solid;
		border-color: @text-color-secondary transparent;

		animation: spinner 2.5s;
		-webkit-animation: spinner 2.5s;
		animation-timing-function: ease;
		-webkit-animation-timing-function: ease;
		animation-iteration-count: infinite;
		-webkit-animation-iteration-count: infinite;
	}
}

.loading-spinner-inline {

	// actual spinner
	&:before {
		content: '';
		display: inline-block;

		width: @spinner-size * 0.8;
		height: @spinner-size * 0.8;
		margin: 0px 0.5em;
		vertical-align: top;

		border-radius: 100px;
		border-width: 2px;
		border-style: solid;
		border-color: @text-color-secondary transparent;

		animation: spinner 2.5s;
		-webkit-animation: spinner 2.5s;
		animation-timing-function: ease;
		-webkit-animation-timing-function: ease;
		animation-iteration-count: infinite;
		-webkit-animation-iteration-count: infinite;
	}
}



// PRINT

@media print {
	.loading-spinner {
		display: none;
	}
}
