
.wide-attribute {
  max-width: none;
}

.note-section {
  border-bottom: 1px solid #e3e5e8;
  border-top: 1px solid #e3e5e8;
  padding: 1.5em 0 1.5em .5em;
  margin: 0 0 2em 0;
}
