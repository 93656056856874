/*
*   BUTTONS
*
*   Styles for button UI elements. Based on Bootstrap button styles.
*
*/

.btn {
    // basic styles and default color
    .buttony();
    display: inline-block;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    white-space: nowrap;

    // BUTTONS THAT ARE "LOADING"

    &.btn-link {
      font-weight: 500;
      text-decoration: underline;
      border: none;
      background: none;
      box-shadow: none;
    }

    & .sub {
        font-size: .8em;
        opacity: 0.8;
        margin-right: 0.5em;
    }

    // BUTTON FONT SIZES

    &.btn-xl {
        font-size: @font-size-xl;
    }
    &.btn-lg {
        font-size: @font-size-l;
    }
    &.btn-md {
        font-size: @font-size-m;
    }
    &.btn-sm {
        font-size: @font-size-s;
    }
    &.btn-xs {
        font-size: @font-size-xs;
    }


    // BUTTON COLORS
    &.btn-white {

        @btn-white-color: white;

        color: @text-color-primary;

        .box-shadow(0 2px 2px @clear-smoke-03);
        .gradient( @btn-white-color , @btn-white-color , @smoke-96 );

        &:hover {
            .gradient( @white, @white , @smoke-97 );
        }
        &:active {
            .gradient( @smoke-97, @smoke-97 , @smoke-92 );
        }
    }

    &.btn-primary {
        @button-color: @blue;
        color: @white;
        border: 1px solid @button-color;
        .box-shadow(0 2px 2px @clear-smoke-10);
        .gradient( @button-color , lighten(@button-color, 5%) , darken(@button-color, 5%) );

        &:hover:not(.btn-hollow) {
            background: lighten(@button-color, 5%);
        }
        &:active {
            background: darken(@button-color, 5%);
        }
        &.btn-hollow {
            color: @button-color;
        }
    }

    &.btn-secondary {
        background: @white;
        color: @blue;
        &.btn-hollow {
            color: @smoke-30;
        }
    }

    &.btn-smoke {
        @button-color: @text-color-primary;

        color: @white;

        border: 1px solid @button-color;
        .box-shadow(0 2px 2px @clear-smoke-10);
        .gradient( @button-color , lighten(@button-color, 5%) , darken(@button-color, 5%) );

        &:hover {
            color: white;
            .gradient( lighten(@button-color, 5%) , lighten(@button-color, 10%) , @button-color );
        }
        &:active {
            .gradient( darken(@button-color, 5%) , @button-color , darken(@button-color, 10%) );
            color: white;
        }
    }

    &.btn-red {
        @button-color: desaturate(@red, 10%);

        color: @white;

        //border: 1px solid @button-color;
        border: 1px solid @button-color;
        .box-shadow(0 2px 2px @clear-smoke-10);
        //.gradient( @button-color , lighten(@button-color, 5%) , darken(@button-color, 5%) );
        background: @button-color;

        &:hover:not(.btn-hollow) {
            color: white;
            .gradient( lighten(@button-color, 5%) , lighten(@button-color, 5%) , @button-color );
        }
        &:active {
            .gradient( darken(@button-color, 5%) , @button-color , darken(@button-color, 10%) );
            color: white;
        }
        &.btn-hollow {
            color: @button-color;
        }
    }

    &.btn-transparent {
        .box-shadow(none);
        background: none;
        border: 1px solid transparent;

        &:hover {
            border: 1px solid @smoke-90;
        }
    }

    &.btn-hollow {
        background: none;
        border: 1px solid @smoke-90;
        transition: border 0.5s ease;
        &:hover {
            border: 1px solid currentColor;
        }
    }

    &.btn-light-weight {
        font-weight: 400;
    }

    &.btn-on-map {
        .box-shadow(0 1px 1px @smoke-30);
    }

    // DISABLED
    // disabled styles in "buttony" mixin get overwritten by button color classes

    &:disabled,
    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
        //color: @text-color-tertiary !important;
        //border: 1px solid @smoke-80 !important;
        //.gradient( @smoke-98 , @white , @smoke-97 ) !important;
        //.box-shadow(0 2px 2px @clear-smoke-05) !important;
        //
        //&:hover,
        //&:active {
        //    color: @text-color-tertiary !important;
        //    border: 1px solid @smoke-80 !important;
        //    .gradient( @smoke-98 , @white , @smoke-97 ) !important;
        //    .box-shadow(0 2px 2px @clear-smoke-05) !important;
        //}
    }

} // end button/btn styles


// BTN BLOCK
// centered button that doesn't go full 100% width

.btn-block {
    display: block;
    width: 100%;
    margin: 0 0 1em;
    padding: 0.75rem 0.75rem;

    // when it's the last thing, margin throws spacing off
    &:last-child {
        margin-bottom: 0;
    }
}

.btn-block-small {
    .btn-block();

    max-width: 200px;
    margin: 0 auto;
}

// Inline button for easy side-by-side display
.btn-inline,
&.btn-inline {
  display: inline-flex;
  margin-left: .5em;
  margin-right: .5em;
}


// TINY BUTTONS
// really small buttons

.btn-tiny {
    font-size: @font-size-xs;
    line-height: 11px;
    border-radius: 3px;
    padding: 5px 8px;
}


// PRINT STYLES

@media print {

    button,
    .btn {
        display: none !important;
    }

    button,
    .btn-transparent {
        display: inline!important;
    }
}
