/*
*	ACTIVITY LIST
*
*	Styles for lists of activities. Deprecates activity streams.
*
*/

.unknown-type {
    color: @text-color-tertiary;
}

.show-more {
    margin-top: 2em;
}

.activity-input-amount {
    text-transform: lowercase;
}

.activity-list-item {
    display: block;
    margin: 0;
    padding: 1.5em 1em;
    border-bottom: 1px solid @separator-color;

    &:hover {
        background-color: @smoke-98;
    }

    .type {
        font-size: @font-size-l;
        font-weight: 500;
        margin: 0 0 .5em 0;
    }

    .sub {
        font-weight: 500;
        color: @text-color-secondary;

        &.inputs {
            font-size: @font-size-s;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0 0 .5em 0;
        }
    }

    .image-thumbnail, .map-image {
        margin: .3em .5em .5em 0;
    }

}
