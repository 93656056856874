/*
*	INPUTS
*
*	Styles for inputs aspect
*
*/

.inputs-list-wrapper {
    .input-row {
        border: 1px solid @smoke-80;
        .gradient(@smoke-96, @smoke-99, @smoke-95);
        box-shadow: 0 2px 3px @clear-smoke-05, inset 0 1px 0 white;
        border-radius: 2px;
        position: relative;
        margin-bottom: 8px;
        width: 100%;
        padding: 1em;

        .title {
            font-size: @font-size-l;
            color: @text-color-primary;
            margin-bottom: 0px;
            font-weight: 500;
        }
        .sub {
            font-size: @font-size-s;
            color: @text-color-secondary;
            font-weight: 500;
        }
    }

}

.crop-input-compact {
  background: @smoke-97;
  margin-bottom: 0.5em;
  border-bottom: none;
}

@media print {
    .inputs-list-wrapper {
        .input-row {
            border: none;
        }
    }
}
