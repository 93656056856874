/*
*   FIELD GROUP FILTER
*
*   Styles for the field group filter in the sidebar
*
*/

.field-group-filter {
    margin-bottom: 1.5em;
    list-style: none;

    .control-group {
        margin: 0 0 1em;
    }
    label {
        -webkit-transition: all .2s ease;
        -moz-transition: all .2s ease;
        transition: all .2s ease;
        //display: block;
        overflow: hidden;
    }
    .clear-filter {
        display: block;
    }
    .trigger-filter {
        display: none;
        margin: -1em 0 0;
    }

    &.collapsed {
        label {
            height: 0;
            min-height: 0;
            margin: 0;
        }
        .clear-filter {
            display: none;
        }
        .trigger-filter {
            display: block;
        }
    } // end collapsed
}

