
.question-mark-helper {
  margin-left: 3px;
  margin-right: 3px;
  transition: opacity 200ms ease;

  img {
    height: 16px;
    position: relative;
    top: 3px;
  }

  &:hover {
    opacity: .8
  }
}

.helper-tooltip {
  position: relative;
  text-transform: initial;

  .wrapper {
    position: absolute;
    background-color: @smoke-20;
    color: @white;
    width: 400px;
    top: 15px;
    left: -190px;
    padding: 15px;
   // border: 1px #ededed solid;
    border-radius: 4px;
    .box-shadow(0 2px 4px 2px @clear-smoke-10);
    z-index: 1000;

    &:after, &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: @smoke-20;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
     // border-color: rgba(237, 237, 237, 0);
     // border-bottom-color: #ededed;
      border-width: 11px;
      margin-left: -11px;
    }
  }
}
