/*
*	TABLES
*
*	Styles for HTML tables. Based on Bootstrap tables.
*
*/


// BASIC STYLES
// styles that apply to all tables

table {
	color: @text-color-primary;
    width: 100%;
    margin: 0 0 1em;

    td {
        padding: 8px;
    }

    // for analytics clickability on drill-down items
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.table-row-clickable {
	tbody tr:hover {
		background: @smoke-99;
		cursor: pointer;
	}
	&.row-stripes {
        tbody tr:nth-child(even):hover td {
            background-color: @smoke-96;
        }
    }
}


// FIELD LIST
// detailed list of fields on field index

.table-field-list {
	//margin-top: -20px;
	th,
	td {
		border-top: 0;
		border-bottom: 1px solid @separator-color;

        &:first-child {
            padding-left: 18px;
        }

        .title {
            font-size: @font-size-l;
            line-height: @font-size-xl;
        }
	}
}


// CHECKLIST
// table with checkboxes

.checklist-table {
	border-bottom: 1px solid @separator-color;
	margin: 0 0 2em;
	width: 100%;

	tr {
		border-top: 1px solid @separator-color;
	}
	td {
		padding: .5em;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
}

// TODO: combine these
.checkbox-table thead tr th:first-child {
    width: 1px; // checkbox col should be skinny
}


// REPORT TABLE
// generic report-style table

.report-table {
    width:100%;
    vertical-align: middle;

    thead {
        font-weight: bold;
        text-align: left;

        th,
        td {
            padding: 8px 12px;

            .small-caps-heading();
            color: @text-color-secondary;

            // tablesorter
            &.header {
                cursor: pointer;
                font-weight: bold;
                background-repeat: no-repeat;
                background-position: center left;
            }
            &.tablesorter-headerAsc,
            &.tablesorter-headerDesc {
                background-color: @smoke-96;
                position: relative;

                &:after {
                    .before-after();
                    top: 17px;
                    right: 12px;
                }
            }
            &.tablesorter-headerAsc:after {
                border-width: 0 4px 4px 4px;
                border-color: transparent transparent @smoke-40 transparent;
                border-style: solid;
            }
            &.tablesorter-headerDesc:after {
                border-width: 4px 4px 0 4px;
                border-color: @smoke-40 transparent transparent transparent;
                border-style: solid;
            }

        }
    }

    tbody {
        td {

            &:first-child {
                white-space: nowrap;
            }

            padding: 8px 12px;
            border: 1px solid hsl(0, 0%, 94%);
            border-width: 1px 0;

            &.small { font-size: @font-size-m; }
            &.right { text-align:right; }
            &.thin { width:0; }

            &.reset {
                text-align:center;
                font-style:italic;
            }
        }
    }

    .sub {
        color: @text-color-secondary;
    }
}


// INFO TABLE
// tiny table to show stats about things like bins and buyers
// should probably be deprecated

table.info-table {
    width: 100%;
    border-radius: 3px;
    margin-bottom: 2em;

    th,
    td {
        width:50%;
        padding: 4px 0;
        vertical-align: top;
    }
    th {
        color: @text-color-secondary;
        font-weight: 500;
        text-align: left;
    }
    td {
        color: @text-color-primary;
        font-weight: 500;
        text-align: right;
    }
}


// wrap <table>s in this to make them scroll on smaller screens
.table-wrapper {
	overflow: auto;
}

tr.save-pending {
    opacity: .5;
    background: @smoke-94;
}

// tablesorter
table.tablesorter th {
    cursor: pointer;
}

@media only screen and (max-width: @screen-md-min) {
    .report-table {
        display: block;
    }
}
