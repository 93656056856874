/*
*	CHOOSER
*
*	Styles for fl-chooser, the input picker that replaced the select2 library
*
*/

.Chooser {
	width: 100%;
}

.Chooser .Select-multi-value-wrapper {
	padding: 2px;
}

.Chooser.Chooser-hasError {
	.Select-control {
		border-color: @red;
		border-width: 2px;
	}
}

.Chooser-value {
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
	justify-content: space-between;
  margin: 2px;
  padding: 5px;


  &.isHover {
    opacity: 0.85;
  }

  &:not([class*="crop-color-"]) {
    background: @blue;
    color: white;
  }
}

.Chooser-value-icon {
  cursor: pointer;
	display: block;
	height: 12px;
	width: 12px;
}

.Chooser-value-label {
	cursor: default;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.357;
	padding-right: 5px;
}

// wrapper
.fl-chooser {
	position: relative; // so the dropdown doesn't float away
	display: inline-block;
}

// placeholder
.chooser-placeholder {
	color: @text-color-secondary;

	// don't break the line, show "..." instead
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;

	&:hover {
		color: @text-color-secondary;
	}

	&.fl-has-content {
		color: @text-color-primary !important;

		&:hover {
			color: @text-color-primary !important;
		}
	}
}

// menu
.fl-chooser-menu {
	position: absolute;
	left: 0%;
	top: 0;
	//margin: 0 0 0 -100px;

	width: 260px;

	display: block;
	border: 1px solid @smoke-80;
	border-radius: 3px;
	border-radius: 0px;

	background: white;
	z-index: 1999;

	font-weight: 500;

	box-shadow: 0 4px 8px @clear-smoke-20;


	.results {
		.empty {
			background: @smoke-93;
			text-align: center;
			width: 100%;
			color: @text-color-secondary;
			padding: 3em 0;
			border-bottom: 1px solid @separator-color;
		}
		.result-list {
			max-height: 230px;
			overflow-y: auto;
			border-bottom: 1px solid @separator-color;

			.result-header {
				background: @smoke-93;
				color: @text-color-secondary;
				text-transform: uppercase;
				font-size: @font-size-s;
				font-weight: bold;
				padding: 0 1em;
				//margin: 1em 0 0;
			}

			.focused {
				background: @smoke-98;
			}
			.result-group {

			}
			&.with-images .result {
				.title,
				.sub {
					margin-left: 40px;
				}
			}
		}
	}

	.result {
		padding: .5em 1em;
		border-bottom: 1px solid @separator-color;
		cursor: pointer;
		position: relative;
		overflow: auto;
		min-height: 30px;
		text-align: left;
		line-height: 1.5;

		&:last-child {
			border: none;
		}

		&.selected:after {
			.before-after();
			width: 16px;
			height: 16px;
			margin: -8px 0 0 0;
			top: 50%;
			right: 16px;
			background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAA40lEQVRYR+2Wyw6EIAxF9cvH+fMZDCXV9HFbAmxwQxTiOZZL43ksvs7F/GMLzKzAVbebxvt2lkCBfqrA9z82iRkCHE6ZbxKjBST4Q2KkgAUvEncVRglAcC2EYloDDQuGSwJqWkGBEPwtYKYVEAjDuYCbVkcgBUcFWmIViTQc2QLOfHSwOtEF90IofTCX6IZbx5D6tiZRnntr6Dib8dEakfd11kulrVLXW50wIxGCa1vAbSMSYTgiUNYgEik4KuBJpOERAU2iCx4VeEt0wzMCJMFH85x7k6N+SDxum98CuwLLK/ADHfE4IYm3gh8AAAAASUVORK5CYII=);
			background-size: cover;
			opacity: .5;
		}

		&.secondary {
			background: @smoke-96;

			&:hover,
			&:focus {
				background: @smoke-94;
			}

			&.focused {
				background: @smoke-94;
			}

		}

		&.highlighted {
			background: @blue;
			color: @text-color-tertiary;

			&:hover {
				background: @dark-blue;
			}
		}

		&:hover,
		&:focus {
			background: @smoke-98;
		}

		// contents
		.icon {
			width: 30px;
			height: 30px;
			float: left;
			border-radius: 3px;
			background: @smoke-90;
			margin: 4px 1em 4px 0;
		}
		.title {
			font-size: @font-size-l;
			color: @text-color-primary;
			margin: 0;
		}
		.sub {
			font-size: @font-size-m;
			color: @text-color-secondary;
		}
	}

	&.hidden {
		display: none;
	}

	.tabs {
		width: 100%;
		overflow: auto;

		.tab {
			float: left;
			padding: .666em;
			border-right: 1px solid @separator-color;
			border-bottom: 1px solid @separator-color;
			width: 50%;
			//margin: 0 -1px 0 0; //for border
			text-align: center;
			color: @text-color-primary;
			cursor: pointer;

		    .gradient( @smoke-97, @smoke-97, @smoke-93 );

			&:hover {
			    .gradient( @smoke-98, @smoke-98, @smoke-94 );
			}
			&.active {
				border-bottom: none;
			    .gradient( @smoke-99, @smoke-99, white );
			}
			&.active:hover {
			    .gradient( white, white, white );
			}

			&:last-child {
				border-right: none;
			}
		}
	}
	.filter {
		border-bottom: 1px solid @separator-color;
		box-shadow: 0 2px 2px @clear-smoke-05;
		position: relative;

		.icon {
			width: 16px;
			height: 16px;
			position: absolute;
			top: 12px;
			left: 12px;
			opacity: .5;
		}

		.clear {
			width: 30px;
			height: 30px;
			position: absolute;
			top: 7px;
			right: 0;
			cursor: pointer;
			font-size: 20px;
			color: @smoke-70;
			line-height: 1;
			text-align: center;
			opacity: .7;

			&:hover {
				opacity: 1;
			}
		}

		input {
			border-radius: 3px 3px 0 0;
			width: 100%;
			padding: 10px 1em 10px 2.5em;
			margin: 0;
			font-size: inherit;
			line-height: 20px;
			border: none;
			box-shadow: none;
			text-align: left;
			color: @text-color-primary;
			&:focus {
				background: white;
				border: none;
			}

		}
	}
	.add-new {
		box-shadow: 0 -2px 2px @clear-smoke-05;
		border-radius: 0 0 3px 3px;
		padding: .5em;
	}
}
