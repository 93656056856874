
.sidebar-card {
  .card-title {
    margin: 0;
    padding: .5em 1em;
    font-size: @font-size-l;
    font-weight: 500;
    background-color: @smoke-97;
    border-bottom: 1px solid @smoke-80;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    &.collapsed {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      border-bottom: none;
    }

    .icon-root {
      margin-top: 2px;
    }

    .icon {
      fill: @text-color-primary;
    }
  }
}
