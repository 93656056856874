/*
*	CALLOUT
*
*	Styles for callouts (boxes, containers, etc)
*   Replaces bootstrap alerts
*
*/

.callout {
	padding: 1em;
	border-radius: 2px;
	margin: 0 0 1em;
	position: relative;

	margin-bottom: 1em;
	color: @text-color-primary;
	font-weight: 500;

	h3,
	h4 {
		margin: 0 0 .5em;
	}
	p {
		font-weight: 500;
	}

	.progress {
		margin: 0 0 .5em 0;
	}

	// last thing in the callout doesn't push the bottom down
	& > *:last-child {
		margin-bottom: 0;
	}


	// SIZES

	&.callout-l {
		padding: 2em;
		margin: 0 0 2em;
	}


	// POINTER

	&.callout-pointer-top:after {
		.before-after();
		border-color: @smoke-96 transparent;
		border-style: solid;
		border-width: 0 8px 8px 8px;

		top: -8px;
		left: calc(~"50% - 8px");
	}
	&.callout-pointer-right:after {
		.before-after();
		border-color: transparent @smoke-96;
		border-style: solid;
		border-width: 8px 0 8px 8px;

		right: -8px;
		top: calc(~"50% - 8px");
	}
	&.callout-pointer-bottom:after {
		.before-after();
		border-color: @smoke-96 transparent;
		border-style: solid;
		border-width: 8px 8px 0 8px;

		bottom: -8px;
		left: calc(~"50% - 8px");
	}
	&.callout-pointer-left:after {
		.before-after();
		border-color: transparent @smoke-96;
		border-style: solid;
		border-width: 8px 8px 8px 0;

		left: -8px;
		top: calc(~"50% - 8px");
	}


	// COLORS

	background: @smoke-96;

	&.callout-yellow {
		background: @light-yellow;

		&.callout-pointer-top:after    { border-color: @light-yellow transparent; }
		&.callout-pointer-right:after  { border-color: transparent @light-yellow; }
		&.callout-pointer-bottom:after { border-color: @light-yellow transparent; }
		&.callout-pointer-left:after   { border-color: transparent @light-yellow; }

	}

  &.callout-dark-yellow {
    background: @yellow;
    color: @smoke-10;

    &.callout-pointer-top:after    { border-color: @yellow transparent; }
    &.callout-pointer-right:after  { border-color: transparent @yellow; }
    &.callout-pointer-bottom:after { border-color: @yellow transparent; }
    &.callout-pointer-left:after   { border-color: transparent @yellow; }

  }


	&.callout-white {
		background: white;
		box-shadow: inset 0 0 0 1px @separator-color;
	}

	&.callout-green {
		background: @green;
		color: white;
		h3, p, a {
			color: white;
		}

		&.callout-pointer-top:after    { border-color: @green transparent; }
		&.callout-pointer-right:after  { border-color: transparent @green; }
		&.callout-pointer-bottom:after { border-color: @green transparent; }
		&.callout-pointer-left:after   { border-color: transparent @green; }

	}

	&.callout-light-green {
		background: lighten(@green, 50%);
		border: 1px solid lighten(@green, 10%);
		color: @green;
		h3, p, a {
			color: @green;
		}
	}

	&.callout-red {
		background: @red;
		color: white;
		h3, p, a {
			color: white;
		}

		&.callout-pointer-top:after    { border-color: @red transparent; }
		&.callout-pointer-right:after  { border-color: transparent @red; }
		&.callout-pointer-bottom:after { border-color: @red transparent; }
		&.callout-pointer-left:after   { border-color: transparent @red; }

	}

	&.callout-light-red {
		background: lighten(@red, 35%);
		border: 1px solid lighten(red, 10%);
		color: @red;
		h3, p, a {
			color: @red;
		}
	}

	&.callout-smoke {
		background: @smoke-40;
		color: white;
		h3, p, a {
			color: white;
		}

		&.callout-pointer-top:after    { border-color: @smoke-40 transparent; }
		&.callout-pointer-right:after  { border-color: transparent @smoke-40; }
		&.callout-pointer-bottom:after { border-color: @smoke-40 transparent; }
		&.callout-pointer-left:after   { border-color: transparent @smoke-40; }

	}

	&.callout-light-smoke {
		background: @smoke-96;
		color: @text-color-primary;
		h3, p, a {
			color: @text-color-primary;
		}

		&.callout-pointer-top:after    { border-color: @smoke-96 transparent; }
		&.callout-pointer-right:after  { border-color: transparent @smoke-96; }
		&.callout-pointer-bottom:after { border-color: @smoke-96 transparent; }
		&.callout-pointer-left:after   { border-color: transparent @smoke-96; }

	}

	&.callout-dark {
		background: @smoke-10;
		color: @white;
		h3, p, a {
			color: @white;
		}

		&.callout-pointer-top:after    { border-color: @smoke-10 transparent; }
		&.callout-pointer-right:after  { border-color: transparent @smoke-10; }
		&.callout-pointer-bottom:after { border-color: @smoke-10 transparent; }
		&.callout-pointer-left:after   { border-color: transparent @smoke-10; }
	}

	// SPECIAL

	// useful for when the ribbon is there
	&.callout-no-overflow {
		overflow: hidden;
	}

	// doesn't go 100% of the screen
	&.callout-partial-width {
		max-width: 400px;
		margin: 0 auto;
	}


}

@media print {

	.callout {
		display: none;
	}

}
