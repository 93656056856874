// Hacks that forces the old Backbone views to play nice within the new navigation

.new-navigation .leaflet-top {
  top: 0 !important;
}

#main.new-navigation .analytics-page {
  margin: 0 0.5rem;
  padding: 1.25rem 1rem;
  max-width: 87.5rem;
  min-height: 100%;
}
#main.new-navigation .analytics-page .headline {
  padding: 0;
  margin-bottom: 1.5rem;
  border-bottom: none;
}

#main.new-navigation .analytics-page .headline .with-toolbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid hsl(216, 10%, 90%);
  padding-bottom: 1.5rem;
}
#main.new-navigation .analytics-page .headline .with-toolbar.no-border {
  border-bottom: none;
  padding-bottom: 0;
}

#toolbar {
  @media only screen and (max-width: 62.5em) {
    display: none;
  }
}

#main.new-navigation .analytics-page .headline h1 {
  line-height: 2.5rem;
  margin-top: 0px;
  margin-bottom: 0px;
}
body.withPrimaryBanner {
  .billingFunnel {
    position: fixed;
    top: 51px !important;
    right: 0;
    bottom: 0
  }
  .primaryBanner div p {
    margin-right: 2rem;
  }
}


@desktop: ~"only screen and (min-width: 62.5rem)";
@mobile: ~"only screen and (max-width: 62.5rem)";
//@large: ~"only screen and (min-width: 376px) and (max-width: 62.5rem)";
//@medium: ~"only screen and (min-width: 361px) and (max-width: 375px)";
//@small: ~"only screen and (max-width: 360px)";


.new-navigation #map-wrapper {
  @media @desktop {
    margin-top: 0;
    top: 0;
  }
}


body.withPrimaryBanner .new-navigation #map-wrapper {
  @media @desktop {
    top: 51px;
  }
  @media @mobile {
    top: 0;
    z-index: 1000;
  }

  #map-container .year-picker-button {
    @media @mobile {
      height: 27px;
    }
  }
}

@media @mobile {
  body.hideNavMenuMobile #mobileMenuContainer {
    display: none;
  }
}
