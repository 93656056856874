/*
*	ACTIVITY INPUTS
*
*	Styles for adding and displaying inputs on an activity.
*
*/

.activity-input {
    padding: .6em;
    border: 1px solid @smoke-80;
    box-shadow: 0 2px 4px @smoke-96, inset 0 1px 2px white;
    border-radius: 3px;
    position: relative;

    .product-prompt {

        .small-caps-heading {
            margin: 0 12px 0 12px;
        }

        .chooser-placeholder-container {
            display: inline-block;
        }

        .chooser-placeholder {
            vertical-align: middle;
        }
    }

    .name {
        float: left;
        font-size: @font-size-m;
        font-weight: 500;
        margin: 5px 0 5px .5em;
        max-width: 120px;

        .sub {
            font-size: @font-size-s;
            color: @text-color-secondary;
            font-weight: 500;
            text-transform: lowercase;
        }
    }
    .amount {
        float: right;
        margin: 4px 40px 0 0;
        text-align: right;

        input {
            font-size: @font-size-l;
            padding: 2px 10px;
            margin: 0 4px 0 0;
            color: hsl(220, 10%, 40%);
            box-shadow: none;
            border: 1px solid @smoke-80;
            text-align: right;
            position: relative;
            top: 3px;

            &:focus {
                background: white;
            }

            &.error {
                border-color: @dark-red;
                .box-shadow(0 0 0 3px fade(@dark-red, 20%) );
            }
        }
    }

    .close {
        position: absolute;
        right: 20px;
        top: 50%;
        margin: -12px 0 0;
        font-size: 24px;
        font-weight: 400;
        color: @text-color-primary;
        opacity: .8;
    }

    .help-inline {
        display: block;
        clear: both;
        padding: 0;
    }
}
