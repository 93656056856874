/*
*	ACTIVITY PATH
*
*	Styles for activity recordings and path stats.
*
*/

.path-contain {
    margin: 2em 0;
}

@playback-control-height: 36px;

.activity-recording {
    border-radius: 4px;
    position: relative;
    width: 520px;
    height: 550px;
    background: @smoke-90;

    overflow: hidden; // so that the playback controls "dip" out of sight

    .recording-timestamp {
        font-size: @font-size-s;
        font-weight: 500;
        fill: @white;
        background-color: @smoke-30;
        width: auto;
        position: absolute;
        text-align: center;
        padding: .4em;
        border-radius: 3px;
        color: @white;
        margin: 6px 6px 0 0;
    }

    // animated activity path overlays
    .activity-path,
    .activity-ghost {
        fill: none;
    }

    .activity-path {
        stroke: @yellow;
        stroke-opacity: 1;
    }
    .activity-ghost {
        stroke: @white;
        stroke-opacity: 2;
    }

    // arrow that shows current location and bearing
    .activity-playback-arrow {
        position: absolute;
        top: 0;
        left: 0;
        stroke: @white;
        stroke-width: 1;
    }

    // transparent backdrop to trap click events for play/pause
    .playback-backdrop {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: @playback-control-height;
        cursor: pointer;
        -webkit-transition: bottom .2s ease;
        transition: bottom .2s ease;
    }

    // progress indicator
    .playback-control-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: @smoke-30;
        height: @playback-control-height;

        opacity: 1;

        -webkit-transition: bottom .2s ease;
        transition: bottom .2s ease;

        &:hover {
            opacity: 1;
        }

        .recording-duration {
            font-size: @font-size-xs;
            font-weight: 500;
        }

        .playback-track {
            position: absolute;
            bottom: 4px;
            left: 30px;

            .playback-slider-handle {
                fill: @white;
                cursor: pointer;
                //stroke: hsl(0, 0%, 0%);
                //stroke-opacity: 0.3;
                //stroke-width: 0px;
            }
            .playback-control-progress {
                fill: none;
                stroke: #fff;
                stroke-opacity: .6;
                stroke-width: 8px;
                stroke-linecap: round;
                cursor: pointer;
            }
            .playback-control-track {
                fill: none;
                stroke: @smoke-10;
                stroke-opacity: .5;
                stroke-width: 8px;
                stroke-linecap: round;
                cursor: pointer;
            }
        }

    }

    // Play, pause, and replay icons
    .playback-indicator {
        position: absolute;
        bottom: 8px;
        left: 10px;
        margin: -25px -25px 0 0;
        width: 20px;
        height: 20px;
        display: none;
        cursor: pointer;
        fill: @white;

        transition: opacity .1s ease;
        -webkit-transition: opacity .1s ease;

        rect,
        path,
        polygon {
            box-shadow: 0 2px 4px @smoke-98;
        }

        &.pause,
        &.refresh {
            width: 16px;
            height: 16px;
        }
    }

    &[data-playback-state="initial"] {
        .playback-indicator.play {
            display: block;
        }
    }
    &[data-playback-state="paused"] {
        .playback-indicator.play {
            display: block;
        }
    }
    &[data-playback-state="playing"] {
        &:hover {
            .playback-backdrop {
                bottom: @playback-control-height;
            }
            .playback-control-container {
                bottom: 0;
            }
            .playback-indicator.pause {
                opacity: 1;
            }
        }
        /*.playback-backdrop {
            bottom: 0px;
        }
        .playback-control-container {
            bottom: -1 * @playback-control-height;
        } */
        .playback-indicator.pause {
           // opacity: 0;
            display: block;
        }
    }
    &[data-playback-state="finished"] {
        .playback-indicator.refresh {
            display: block;
        }
    }
}

// PATH STATS BAR

.path-stats {
    background-color: @smoke-98;
    text-align: center;
    padding: 1em 0;
    font-size: @font-size-s;
    max-width: 520px;

    .stat {
        width: 25%;
        display: inline-block;

        span {
            font-size: @font-size-s;
            width: 100%;
            font-weight: 500;
        }

        div {
              color: @text-color-secondary;
              text-transform: uppercase;
              font-weight: 500;
              line-height: 20px;
              margin-top: .3em;
        }
    }

}
