/*
*	SOIL
*
*	Styles for soil texture reports
*
*/


.soil-legend {
    font-size: @font-size-l;
    line-height: 1.5;
}
.soil-legend-swatch {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin-right: 5px;
    float: left;
}

table.soil-table {
    margin: 0 0 2em 30px;

    tr {
        &:last-child td {
            border-bottom: 1px solid @separator-color;
        }
        td {
            border-top: 1px solid @separator-color;

            &:first-child {
                padding-left: 0px;
            }
            &:last-child {
                width: 150px;
            }

            .title,
            p,
            .sub {
                display: block;
                clear: both;
                margin: 0;
            }
            .title {
                color: @text-color-primary;
            }
            p,
            .sub {
                color: @text-color-secondary;
            }
        }
    }
}
