/*
*	HELPERS
*
*	Helper text styles
*
*/

.question-tooltip-target {
    background: @clear-smoke-50;
    color: white;
    font-weight: 500;
    text-align: center;
    width: 14px;
    height: 14px;
    opacity: .8;
    line-height: 14px;
    font-size: @font-size-xs;
    border-radius: 12px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    text-indent: 1px;
    text-transform: none;

    &:hover {
        opacity: 1;
    }
}

