/*
*	PANEL
*
*	Styles for panel
*
*/


// PANEL

.panel {
  border-radius: 0.25rem;
  border: 1px solid @smoke-80;
  padding: 50px;
}
