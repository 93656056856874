/*
*	ITEMS DETAILS
*
*	Styles for expanded, detailed lists
*
*/


.field-detail {

    position: relative;

    padding: 1.5em;
    border-bottom: 1px solid @separator-color;

    font-weight: 500;
    background: @white;
    cursor: pointer;
    font-size: @font-size-m;

    &:last-child {
        border-bottom: none;
    }
    &:hover {
        text-decoration: none;
        background: @smoke-98;
    }
    .icon {
        position: relative;
        max-width: 150px;

        img {
            width: 100%;
            max-width: 160px;
            background: @smoke-90;
            border-radius: 2px;
        }
        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .field-detail-block {
        color: @text-color-secondary;

        h4,
        h5 {
            color: @text-color-primary;
            font-size: @font-size-l;
            margin: 0 0 .5em;
            line-height: 1;
        }
    }
}