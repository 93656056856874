/*
*	ITEMS LIST
*
*	Styles for lists of items like fields etc
*
*/


.list-item, .list-header {
  border-bottom: 1px solid #e3e5e8;
}

.list-item {
  display: block;
  margin: 0;
  padding: 1.5em 1em;
}

.list-header {
	padding: 0.5em 1em;
}

.list-heading {
	.small-caps();
	margin-bottom: 0;
}

.section-footer {
  margin: 1em 0;
}

.section-title {
  margin-top: 1.5em;
}
