/*
*	POPOVER
*
*	Styles for bootstrap popovers.
*
*/


.popover {
    width: 200px;
    border-radius: 3px;
    padding: 0;

    .popover-title {
        font-size: @font-size-m;
        font-weight: 500;
        padding: 4px 10px;
    }

    .popover-content {

        hr {
            margin: 6px -14px;
        }
        p {
            margin: 0;
        }
    }

}