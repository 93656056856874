/*
*	TABS
*
*	Global tab styles
*
*/


.tabs {
	.clear-fix();

	.tab {
		position: relative;
		cursor: pointer;

		a {
			.ui-control();
			display: block;

			color: @text-color-secondary;
			font-size: @font-size-l;
			font-weight: 500;

			&:hover {
				color: @text-color-primary;
			}
		}

		// selected/active state
		&.active a {
			color: @smoke-20;
		}

		&.disabled {
			opacity: 0.4;
			cursor: default;
		}
	}


	// HORIZONTAL TABS
	// styles specific to a normal, horizontal tab layout

	&.horizontal-tabs {
		.tab {
			float: left;
			padding: 0.5em 0;
			margin: 0 0.5em;
			font-size: @font-size-l;


			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				color: @smoke-50;
			}

			&.large {
				padding: 1em 0;
			}

			&.medium {
				font-size: @font-size-m;
			}

			&.small {
				font-size: @font-size-s;
			}

			&.active {
				color: @blue;
				border-bottom: 2px solid @blue;

				&:hover {
					color: lighten(@blue, 10%);
					border-bottom-color: lighten(@blue, 10%);
				}
			}
		}
	}


	// VERTICAL TABS
	&.vertical-tabs {

		// positioning for settings tabs
		//margin: -20px 40px 0 -20px;
		//padding: 2em 0;
		min-height: 500px;
		border-right: 1px solid @separator-color;
		margin-right: 3em;

		.tab {
			width: 100%;
			text-align: right;

			a {
				padding: 15px 35px;
			}
		}

	}

}

@media only screen and (max-width: @screen-md-min) {
	.tabs {
		.tab {
			a {
				display: inline-block;
			}
		}

		&.settings-tabs {
			min-height: 0px;
			border-right: none;

			& div :first-child a {
				padding-left: 0;
			}

			& div :last-child a{
				padding-right: 0;
			}

			.tab {
				width: initial;

				a {
					padding: 15px;
				}
			}
		}
	}
	.settings-tabs-container {
		width: 100%;
		overflow: hidden;
		overflow-x: scroll;
		white-space: nowrap;
		background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.75) 90%, rgba(255,255,255,0) 100%);
	}
}
