
.control-group {

  .entity-name {
    padding: 0.5em 1em;
    line-height: 2.5;
    color: @text-color-primary;
    font-weight: 500;
  }

  .control-label-icon {
    width: 24px;
    height: 24px;
  }

   input.datepicker {
    .buttony();
    .box-shadow(none);
    border: 1px solid transparent;
    font-size: @font-size-m;
    line-height: 1.2;
    padding: .5em 0;
    background: white;
    text-align: left;

    &:hover {
        cursor: pointer;
        border: 1px solid @smoke-90;
        background: none;
    }
  }
}
