/*
*	TYPE
*
*	Global typography styles
*
*/


// FONT SIZES
@font-size-default:  16px;
@font-size-xxxl:     1.75rem;       // 28px
@font-size-xxl:      1.5rem;  // 24px
@font-size-xl:       1.25rem;  // 20px
@font-size-l:        1rem;  // 16px
@font-size-m:        0.875rem;       // 14px
@font-size-s:        0.75rem; // 12px
@font-size-xs:       0.625rem; // 10px


// GLOBAL TYPE DEFAULTS

* {
    font-family: 'Rubik', 'Helvetica Neue', 'Helvetica', sans-serif;
}

html,
body {
    color: @text-color-primary;
    font-size: @font-size-default;
}

p {
  font-size: @font-size-m;
}

b, strong {
    font-weight: 500;
}
