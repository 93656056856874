/*
*	STORAGE ICON
*
*	Dynamic grain bin viz that fills and empties
*
*/

.storage-icon {
	overflow: hidden;
	position: relative;
	margin: 0 auto;
	z-index: 0;
	background: white; // keep icon card bg from showing through

	.storage-icon-outline,
	.storage-icon-mask,
	.storage-icon-damper,
	.storage-icon-fill {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
	}

	.storage-icon-outline svg,
	.storage-icon-mask svg,
	.storage-icon-damper svg {
		width: 100%;
		height: 100%;
	}

	// stroke on top of everything to show storage bin shape
	.storage-icon-outline { z-index: 4; }

	// white image with a bin-shaped "punch out" so only bin stuff shows
	.storage-icon-mask    { z-index: 3; }

	// white image that lowers to control how much stuff is in the bin
	.storage-icon-damper  { z-index: 2; }

	// css div representing the "stuff" in the bin
	.storage-icon-fill    { z-index: 1; }
}


// small version for storage index
.small-storage-icon .storage-icon {
	width: 150px;
	height: 150px;
}

// big version for storage detail page
.big-storage-icon .storage-icon {
	width: 240px;
	height: 240px;
	margin-bottom: 2em;
}


