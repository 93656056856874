/*
*   ACTIVITY TYPE ICONS
*
*   Styles for activity type icons on activity index.
*
*/

.activity-type-icon {
    width: 26px;
    height: 26px;
    border-radius: 100px;
    box-shadow: inset 0 0 0 2px @smoke-60;
    line-height: 24px;
    font-size: @font-size-m;
    font-weight: 500;
    color: @text-color-secondary;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
