//general styles
.onboarding-wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
}
.onboarding-helper-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: @clear-smoke-80;
    z-index:1001;
}

.onboarding-helper {
    position: fixed;
    width: 180px;
    top: 0;
    left: 0;
    z-index: 1002;
    .btn {
        margin-top: 1em;
    }
}

//styles for specific popups
//activity helper
.activity-helper {
    top: 224px;
    left: 224px;
}

.noGeo-helper {
    width: 250px;
    margin-top: -74px;
    top:50%;
    margin-left: -125px;
    left:50%;
    .title {
        font-size: @font-size-xl;
        text-align: center;
        margin-bottom: .5em;
        margin-right: 13px;
    }
}

