/*
*	ITEMS CARDS
*
*	Styles for lists in card formatting
*
*/

@item-card-size: 150px;
@item-card-size-small: 135px;
@item-card-size-xsmall: 120px;

.item-card {
    .ui-control();
    position: relative; // contain loading icon

    margin: 2em 2em 2em 0;
    display: inline-block;
    vertical-align: top;

    font-weight: 500;
    width: @item-card-size;

    // bin viz, map thumbnail, tractor pic, etc
    .item-card-image {
        height: @item-card-size;
        width: @item-card-size;
        background: @smoke-90; // until something loads
        position: relative;

        img {
            width: 100%;
            min-height: 90px;
            border-radius: 2px;
        }
        svg {
            position: absolute;
            top: 0;
            left: 0;
        }

        &.item-card-image-bordered {
            border: 2px solid @separator-color;
        }

    }

    // name and info (acreage etc)
    .item-card-content {
        padding: 1em 0;
        color: @text-color-secondary;
        font-size: @font-size-m;

        h3 {
            color: @text-color-primary;
            font-size: @font-size-l;
            margin: 0 0 .5em;
        }
    }

    // when the card is being saved
    &.save-pending {
        opacity: .5;

        &::before {
            .before-after();
            height: 16px;
            width: 16px;
            background: url('../../../assets/images/template/indicator.gif');
            left: 50%;
            top: 50%;
            z-index: 1000;
            margin: -8px 0 0 -8px;
        }
    }

}

// overlay prompt for pin fields - also relevant to items-details layout
.point-field-overlay {
    position: absolute;
    width: 100%;
    height: 150px;
    background: @clear-smoke-80;
    text-align: center;

    h3 {
        color: white;
        padding-top: 26px;
    }
}
