
.recent-image-health-alert {
  text-align: center;
  border: 3px solid @yellow;
  background-color: @yellow;
  text-transform: uppercase;
  font-weight: 500;
}


/*
    CROP HEALTH ALERT BADGE
    Triangle "warning" symbol that indicates a crop health alert
*/

.anomaly-badge {
  svg {
    width: 24px;
  }
}

/*
    CROP HEALTH ALERT BANNER
    Larger banner that lets people see the alert map
*/

.crop-health-alert-banner {
	background: @yellow;
	padding: 1em 1.5em;
	color: @smoke-30;
	line-height: @font-size-m;

	.title {
		color: @smoke-30;
		font-size: @font-size-l;
		font-weight: 500;
	}
	a {
		color: @smoke-30;
		font-size: @font-size-s;
		font-weight: 500;
		text-transform: uppercase;
		text-decoration: underline;
		float: right;
	}

	&.crop-health-alert-banner-cap {
		margin: .5em 0 0;
		border-radius: 3px 3px 0 0;
	}
	&.crop-health-alert-banner-detail {
		margin: 0 auto;
		border-radius: 3px;

		position: absolute;
		width: 320px;
		top: 1em;
		left: 50%;
		.transform( translate(-50%, 0) );
	}
}


.crop-health-index-badge {
	line-height: 20px;
	color: @text-color-primary;
  padding: initial;
  margin: 0 .1em;

	.crop-health-alert-icon {
	  display: inline-block;
	  position: relative;
	  top: 3px;
	  svg {
	    width: 20px;
	  }
	}

}

table .crop-health-index-badge {
	margin-top: 6px;
}
