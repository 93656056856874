/*
*	YIELD
*
*	Styles for showing declared yield and uploaded file results
*
*/


// YIELD BLOCK ON FIELD DETAIL

.yield-info {
	align-items: center;
	display: flex;
	font-weight: 500;

	// map preview
	.yield-info-map {
		.map-empty {
			.ui-control();
			padding: 3em;
			margin: 0 1em 0 0;
			width: 130px;
			height: 130px;

			border: 2px dashed @separator-color;
			border-radius: 4px;

			text-align: center;
			font-weight: 500;

			a {
				color: @text-color-tertiary;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	// yield amount
	.yield-amount {
		.amount {
			font-size: @font-size-xxl;
			font-weight: 400;
		}
		.unit {
			font-size: @font-size-l;
			font-weight: 500;
			margin-left: .33em;
		}
	}

	.subtitle {
		font-size: @font-size-l;
	}
	.sub,
	.sub a {
		font-size: @font-size-m;
		color: @text-color-secondary;
	}
	.sub a {
		text-decoration: underline;
	}
}
