/*
*	FORMS
*
*	Styles for forms and inputs throughout the app
*
*/


// VARIABLES

// for horizontal forms, how far from the left side the input boxes appear
@form-horizontal-label-width: 130px;

// width and height of checkbox and radio inputs
@checkbox-radio-size: 16px;


// MIXINS

.form-label() {
    display: inline-block;
    font-size: @font-size-m;
    line-height: 1.2;

    color: @text-color-primary;
    font-weight: 400;
}

.form-input() {
    display: inline-block;
    font-size: @font-size-l;
    line-height: 1.375;

    color: @text-color-primary;
    font-weight: 400;

    padding: .5em;
    border: 1px solid @smoke-80;
}


//////////////////////////////////////
/////////////// INPUTS ///////////////
//////////////////////////////////////

// TEXT + TEXTAREA

textarea,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="email"],
.uneditable-input {
    .form-input();
    margin: 0; // should be zero, add spacing with table, grid, or nbsp
    outline: none;
    max-width: 100%;
    background: white;
    border-radius: 4px;

    // focus
    &:focus {
        outline: 0;
        border-color: @blue;
    }

    // disabled
    &[disabled] {
        cursor: auto;
        background: @smoke-94;
        color: @text-color-secondary;
    }
  }

.flui-input-compat input {
  border: none !important;
}

textarea,
input[type="text"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="email"],
.flui-input-compat, /* allows legacy classnames to be used on new `<Input/>` component */
.uneditable-input {
    // font-size
    &.input-xxl {
        font-size: @font-size-xxl;
    }
    &.input-xl {
        font-size: @font-size-xl;
    }
    &.input-l {
        font-size: @font-size-l;
    }
    &.input-m {
        font-size: @font-size-m;
    }
    &.input-s {
        font-size: @font-size-s;
    }
    &.input-xs {
        font-size: @font-size-xs;
    }

    // widths
    &.input-30 {
        width: 30px;
    }
    &.input-40 {
        width: 40px;
    }
    &.input-50 {
        width: 50px;
    }
    &.input-60 {
        width: 60px;
    }
    &.input-70 {
        width: 70px;
    }
    &.input-80 {
        width: 80px;
    }
    &.input-90 {
        width: 90px;
    }

    &.input-100 {
        width: 100px;
    }
    &.input-110 {
        width: 110px;
    }
    &.input-120 {
        width: 120px;
    }
    &.input-130 {
        width: 130px;
    }
    &.input-140 {
        width: 140px;
    }
    &.input-150 {
        width: 150px;
    }
    &.input-155 {
        width: 155px;
    }
    &.input-160 {
        width: 160px;
    }
    &.input-170 {
        width: 170px;
    }
    &.input-180 {
        width: 180px;
    }
    &.input-190 {
        width: 190px;
    }

    &.input-200 {
        width: 200px;
    }
    &.input-210 {
        width: 210px;
    }
    &.input-220 {
        width: 220px;
    }
    &.input-230 {
        width: 230px;
    }
    &.input-240 {
        width: 240px;
    }
    &.input-250 {
        width: 250px;
    }
    &.input-260 {
        width: 260px;
    }
    &.input-270 {
        width: 270px;
    }
    &.input-280 {
        width: 280px;
    }
    &.input-290 {
        width: 290px;
    }

}

input[type="file"] {
    line-height: 1;
    color: @text-color-primary;
    margin: 0 0 1em;
    width: 100%;
}

textarea {
    margin: 0 0 1em;
    min-height: 5em;
    max-width: 30em;
    width: 100%;
}

.onboarding-input {
  input {
    height: 40px;
    font-size: @font-size-l;
  }

  input[type="text"] {
    padding: 0.4em 1em;
  }
  input[type="number"] {
    padding: 0.4em 1em;
  }
}

button.onboarding-input {
    height: 40px;
    padding: 0.5em 2em;
    font-size: @font-size-l;
}


// PREFIX AND SUFFIX
// little labels right before and after inputs
// ex: currency signs, units

// Flexbox implementation of prefix
.input-prefix {
  .clear-fix();
  display: flex;
  border: 1px solid @smoke-80;
  background: white;
  border-radius: 3px;
  overflow: hidden;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;

  .prefix,
  .suffix {
    display: flex;
    justify-content: center;
    flex-basis: 1.5em;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
  }

  input {
    flex: 1;
    border: none;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.input-prepend,
.input-append {
    .clear-fix();
    display: inline-block;
    vertical-align: middle;

    input,
    .add-on {
        float: left;
    }
    input {
        margin: 0;
    }
    .add-on {

        .form-input();

        background: @smoke-98;
        border: 1px solid @smoke-80;
        text-shadow: 0 1px 1px @white;
        color: @text-color-primary;
        font-weight: 500;

        &:first-child {
            border-right: transparent;
        }

        &:last-child {
            border-left: transparent;
        }
    }

    .onboarding-add-on {
        height: 40px;
        align-items: center;
        display: flex;
    }

    .select-wrapper {
      margin-left: 0.5em;
    }

    &.error input {
        border-color: @red;
    }

    .caption-label {
        clear: both;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.4;
        color: #454a54;
    }

    .warning-label {
        font-size: 13px;
        font-weight: bold;
        line-height: 1.4;
        color: @red;
    }
}


// CHECKBOXES AND RADIO

input[type="checkbox"],
input[type="radio"] {

    .ui-control();
    -webkit-appearance: none;
    border: none;
    position: relative;
    width: @checkbox-radio-size;
    height: @checkbox-radio-size;

    &:focus {
        outline: none;
        &:before {
            border-color: @text-color-primary; // doesn't inherit psuedo-class styles from buttony() mixin since the entire input box UI is a in a ":before"
        }
    }

    // :before elements are the boxes and circles to-be-checked
    &:before {
        .before-after();
        .buttony();
        padding: 0;
        width: @checkbox-radio-size;
        height: @checkbox-radio-size;
    }

    // :after elements contain the :checked indicators
    &:after {
        .before-after();
    }

}

input[type="checkbox"] {
    box-shadow: none;

    // the box
    &:before {
        border-radius: 2px;
    }

    // the check
    &:after {
        width: 6px;
        height: 14px;
        top: -2px;
        left: 7px;
        border-style: solid;
        border-width: 0 2px 2px 0;
        border-color: text-color-primary;

        opacity: 0; // hide by default

        .transform( rotate(45deg) );
    }
    &:checked:after {
        opacity: 1;
    }

}

input[type="radio"] {
    box-shadow: none;

    // the button
    &:before {
        border-radius: 100px;
    }

    // the little dot on the button
    &:checked:after {
        width: 6px;
        height: 6px;
        top: 5px;
        left: 5px;
        border-radius: 3px;
        background: @text-color-primary;
    }
}


// SELECTS

.select-wrapper {
    position: relative;
    display: inline-block;
    margin: 0; // no margin, use rows and columns for spacing
    max-width: 100%;
    width: 100%;
    color: @smoke-50;

    // little drop-down arrow
    &:after {
        .before-after();
        pointer-events: none;
        border-width: 4px 4px 0;
        border-style: solid;
        border-color: currentColor transparent;
        top: 50%;
        margin: -2px 0 0;
        right: 10px;
    }

    // font-size
    &.select-wrapper-xxl {
        select,
        .fake-select {
            font-size: @font-size-xxl;
        }
    }
    &.select-wrapper-xl {
        select,
        .fake-select {
            font-size: @font-size-xl;
        }
    }
    &.select-wrapper-l {
        select,
        .fake-select {
            font-size: @font-size-l;
        }
    }
    &.select-wrapper-m {
        select,
        .fake-select {
            font-size: @font-size-m;
        }
    }
    &.select-wrapper-s {
        select,
        .fake-select {
            font-size: @font-size-s;
        }
    }
    &.select-wrapper-xs {
        select,
        .fake-select {
            font-size: @font-size-xs;
        }
    }

    select,
    .fake-select {
        display: flex;
        align-items: center;
        // hide browser-based select styles
        -webkit-appearance: none;
        max-width: 100%;
        width: 100%;

        .buttony();
        line-height: 1.2;
        padding: .5em .8em; // match form input
        padding-right: 2em; // room for the little arrow
        text-align: left;
    }

    select::-ms-expand {
        display: none;
    }

    &.select-wrapper-minimal {

        &:after {
            right: 5px;
        }

        select,
        .fake-select {
            border: none;
            border-bottom: 1px dashed @smoke-60;
            background: transparent;
            box-shadow: none;
            border-radius: 0;
            padding-left: 0;
            padding-right: 1.75em;

            &:hover,
            &:active,
            &:focus {
                border-bottom: 1px dashed @smoke-50;
                background: transparent;
            }
        }

    }

  &.select-text {
    select,
    .fake-select {
      background: transparent;
      border: none;
    }
  }

  &.select-blue {
    select,
    .fake-select {
      color: @blue;
      border-bottom: 1px solid @blue;
      border-radius: 0;
    }

    &:after {
      border-top-color: @blue;
    }
  }
}


// firefox fix for select list display
@-moz-document url-prefix() {

    /*
        Firefox doesn't support "appearance: none" for selects
        SEE ALSO: index.html (IE version)
    */

    /* return select to normal */
    .select-wrapper select {
        padding-right: 0 !important;
    }

    /* hide arrow */
    .select-wrapper:after {
        display: none !important;
    }

}
@media print {
    .select-wrapper:after {
        display: none !important;
    }
}


// PLACEHOLDER

::-webkit-input-placeholder {
    color: @text-color-tertiary;
    line-height: 1.2;
}
:-moz-placeholder {
    color: @text-color-tertiary;
    line-height: 1.2;
}
::-moz-placeholder {
    color: @text-color-tertiary;
    line-height: 1.2;
}
:-ms-input-placeholder {
    color: @text-color-tertiary;
    line-height: 1.2;
}


// MISC

// hide spinner arrows for input type="number"
// display: none; <- Crashes Chrome on hover
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
// for Firefox
input[type=number] {
  -moz-appearance: textfield;
}


// DECORATOR
// clickable icons on the right side of text inputs
// often do stuff when clicked
// they look like they're *inside* the input, unlike prefix/suffix

.input-decorator-wrapper {
    position: relative; // contain input-decorator
    display: inline-block;
}
.input-decorator {
    .ui-control();
    max-height: 36px;
    position: absolute;
    right: 3px;
    top: 0;
    opacity: .4;
    margin: -3px;

    &:hover {
        opacity: .6;
    }
    &:active {
        opacity: .8;
    }
}

.input-button-group {
    margin: 10px 5px;
    input {
        margin-right: 0px;
        border-radius: 4px 0px 0px 4px;
    }

    button {
        margin-left: 0px;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid black;
    }
}

//////////////////////////////////////
/////////////// LAYOUT ///////////////
//////////////////////////////////////



// BASIC LAYOUT
// a "normal" form, labels on top of inputs


form {

    fieldset {
        border: none;
        margin: 0 0 2em;
        padding: 0;
    }


}

// a single form item
// ex: "Name: Bob"
.control-group {
    .clear-fix();
    margin: 0 0 1em;

    .control-label {
        .form-label();
        font-size: @font-size-m;
        font-weight: 500;
        color: @text-color-primary;
        margin: 0 0 .5em;
    }

    .controls {

        // radio button and checkbox labels
        label {
            .ui-control();
            display: block;
            position: relative; // don't let checkbox float away
            width: 100%; // prevent inline styles
            padding: 0 2em; // room for checkbox
            line-height: 2;

            input[type="checkbox"],
            input[type="radio"] {
                position: absolute;
                margin: 5px 0;
                left: 0;
            }

        } // end label

    } // end controls

    // when using icon as label
    &.icon-label {
        margin-bottom: 0!important;

        .control-label {
            width: 28px;
            height: 38px;
        }

        .controls {
            margin-left: 40px;
        }
    }

} // end control-group


// asterisk for required inputs
.control-group.required .control-label:after {
    content: '*';
}

.input-note {
    font-size: @font-size-s;
    color: @text-color-secondary !important; // sucky
}

// form actions
// save and cancel buttons, etc

.form-actions {
    background: transparent;
    margin: 2em 0;
}


// HORIZONTAL FORM
// where the labels are to the left of the inputs, not on top

.form-horizontal {
    .control-group {
        margin-bottom: 10px;
    }
    .control-label {
        width: @form-horizontal-label-width;
        max-width: @form-horizontal-label-width;
        float: left;
        text-align: right;
        padding: 1em 0; // align text with input content
    }
    .controls,
    .form-actions {
        margin-left: @form-horizontal-label-width + 20;
        position: relative;

        > input, textarea {
            max-width: 100%;
            width: 100%;
        }
    }
    .last-sub-item {
        margin-bottom: 4rem;
    }
}

// SMALL FORM
// a bit more compact than usual

.form-small {

    &.form-horizontal {
        .control-group {
            .control-label {
                width: 120px;
            }
            .controls {
                margin-left: 130px;
            }
        }

    }
    .control-group {
        margin-bottom: 10px;
    }

    .form-actions {
        padding: 10px;
    }

    textarea {
        max-width: 90%;
    }
}

//////////////////////////////////////
///////////// VALIDATION /////////////
//////////////////////////////////////

.control-group.error {
    //background: hsl(50, 90%, 90%);

    .control-label {
        font-weight: bold;
    }
}
// error & error focus
.control-group.error input,
.control-group.error select,
.control-group.error textarea,
.control-group.error div[type="button"],
.control-group.error input:focus,
.control-group.error select:focus,
.control-group.error textarea:focus,
.control-group.error div[type="button"]:focus,
input.error,
select.error,
textarea.error,
div[type="button"].error,
input.error:focus,
select.error:focus,
textarea.error:focus,
div[type="button"].error:focus {
    outline: 0;
    border-color: @red;
    .box-shadow(0 0 0 3px fade(@red, 20%) );
}

.control-group.error .control-label,
.control-group.error .help-block,
.control-group.error .help-inline,
.control-label.error,
.help-block.error,
.help-inline.error,
.error {
    &, a {
      color: @red;
      font-weight: 500;
    }
    .input-append input {
        border-color: @red;
    }
}

.help-inline,
.help-block {
    //background: lighten(@yellow, 35%);
    color: hsl(20, 80%, 50%);
    font-size: @font-size-m !important;
    font-weight: bold;
}

.help-block {

    &.centered {
        text-align: center;
    }
}



//////////////////////////////////////
//////////////// MISC ////////////////
//////////////////////////////////////

// stuff related to form input besides the forms themselves
// TODO: make each of these its own component?

// REPEATABLE INPUT TABLE
// for when you want to add and remove a list of inputs
// ex: market alerts

.repeatable-input-table {
    margin-bottom: 1em;

    td {
        position: relative; // contain close-x
        padding: 4px 15px 4px 0;
        vertical-align: top;
        min-width: 100px;
    }

    input, select {
        margin-bottom: 0;
    }
    .delete {
        font-weight: 500;
        cursor: pointer;
        color: @text-color-primary;
        font-size: @font-size-xl;
    }
}


// RESULTS BAR
// thing that shows up like in field sharing to show what you just searched for and stuff

.results-bar {
    background: @smoke-96;
    padding: .33em .66em;
    color: @text-color-secondary;
    border: 1px solid @smoke-80;
    border-radius: 3px;
    font-weight: 500;
    margin: 0 0 1em;
    position: relative; // contain close-x

    .close {
        font-weight: 400;
        opacity: .8;
        line-height: .9;
        font-size: 20px;
    }
}



//////////////////////////////////////
/////////////// PRINT ////////////////
//////////////////////////////////////

@media print {

    select {
        -webkit-appearance: none !important;
    }

}
