/*
*	HEADINGS
*
*	Global styles for <h1>, <h2>, etc.
*
*/


h1,
h2,
h3,
h4,
h5,
h6 {
	color: @text-color-primary;
	font-weight: 500;
	line-height: 1;
	position: relative;

	&.heading-underline {
		border-bottom: 1px solid @separator-color;
		padding-bottom: .5em;
		overflow: visible;
	}

	&.heading-clickable {
		cursor: pointer;
		position: relative;
        border-radius: 3px;

	    // special smoke
        .gradient(@smoke-97, @smoke-97, @smoke-94);
        border: 1px solid @smoke-80;
        box-shadow: 0 2px 2px @clear-smoke-05;

		padding-top: .5em;
		padding-bottom: .5em;
		padding-left: 2em;
		padding-right: 1em;

		margin-left: -2em;
		margin-right: -1em;

		&:hover {
	    	.gradient( @smoke-98, @smoke-98, @smoke-96 );
		}

		// if heading-collapsable
		&::after {
			left: 13px;
		}
	}

	&.heading-collapsable {
		cursor: pointer;
		position: relative;
		padding: .5em 0em .5em 1em;

		&::after {
			.before-after();
			border-width: 5px 5px 0;
			border-color: @smoke-50 transparent;
			border-style: solid;
			left: 0;
			top: 17px;
			transition: .2s all ease;
		}
		&.collapsed::after {
			.transform( rotate(-90deg) );
		}
	}
	&.heading-step {
		position: relative;
		&:after {
			.before-after();
			content: attr(data-step-num);
			width: 26px;
			height: 26px;
			line-height: 26px;
			border-radius: 100px;
			font-weight: 500;
			background: @smoke-50;
			color: white;
			text-align: center;
			font-size: @font-size-m;
			top: -3px;
			left: -40px;
		}
	}

	.info {
		color: @text-color-secondary;
		font-size: 0.7em;
		font-weight: normal;
		display: flex;
		margin: 0 0 0 .5em;
		line-height: 1;
		white-space: nowrap;

		&.heading-info-new-line {
			margin: .25em 0 0 0;
			display: flex;
		}
	}

	.heading-info-first-section {
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		vertical-align: bottom;
	}

	&.heading-no-bottom-padding {
		margin-bottom: 0 !important;
	}

	/* inline edit styles */
	.heading-edit {
		float: right;
		opacity: .2;
		cursor: pointer;
		height: 30px;
		width: 30px;
		padding: 6px;
		margin: -6px;

		img {
			height: 18px;
			width: 18px;
		}

		&:hover {
			opacity: .4;
		}
	}

	input[type="text"] {
		margin: -2px 0;
	}

}


// SIZES

h1 {
	font-size: @font-size-xxl;
}
h2 {
	font-size: @font-size-xl;
}
h3 {
	font-size: @font-size-l;
}
h4 {
	font-size: @font-size-m;
}
h5 {
	font-size: @font-size-s;
}
h6 {
	font-size: @font-size-xs;
}


.small-caps {
	font-size: @font-size-s;
	color: @text-color-secondary;
	text-transform: uppercase;
	font-weight: 500;
}

.small-caps-heading {
	.small-caps();
	line-height: 20px;
	margin: 1.5em 0 .5em;
	white-space: nowrap;
}

.heading-back-link {
	.ui-control();
	position: absolute;
	left: -2em;
	top: 0;
	width: 2em;
	height: 2em;

	opacity: .8;

	&:hover {
		opacity: 1;
	}

	&:after {
		.before-after();

		position: absolute;
		left: 50%;
		top: 50%;
		margin: -10px 0 0 -5px;
		width: 18px;
		height: 18px;

		font-weight: 300;
		border-width: 0 0 2px 2px;
		border-style: solid;
		border-color: @smoke-70;

		.transform( rotate(45deg) );
	}

	// temp positioning on activity detail
	&.drop {
    left: -3em;
  }
}


// PRINT
@media print {
	.heading-back-link {
		display: none;
	}
}

@media only screen and (max-width: @screen-md-min) {
	.heading-back-link.drop {
		left: 0;
	}
}
