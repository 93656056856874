/*
*   CROP HISTORY
*
*   Styles for a single year in the crop history
*
*/


.crop-history-row {
    margin-bottom: 1em;

    .crop-history-year {
        .small-caps-heading();
        margin: .5em 0;
    }

    .crop-history-crop {
        margin-bottom: 1em;

        .map-badge {
            border-radius: 3px;
            width: 34px;
            height: 34px;
            line-height: 34px;
            font-size: @font-size-m;
            font-weight: 500;
            display: inline-block;
            margin-right: 1em;
            float: left;

            &.no-crop {
                border: 2px dotted @separator-color;
            }

        }

        .title {
            font-size: @font-size-l;
            color: @text-color-primary;
            font-weight: 500;
        }

        .sub {
            font-size: @font-size-m;
            color: @text-color-secondary;
            font-weight: 500;

            a {
                text-decoration: underline;
            }
        }
    }
}

.no-crops {
    font-size: @font-size-m;
    color: @text-color-secondary;
    font-weight: 500;
    margin-bottom: 15px;

    a {
        text-decoration: underline;
    }
}
