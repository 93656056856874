/*
*	OTHER
*
*	Styles for HTML elements that don't fit in the other files in /elements
*
*   ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ *
*   ~ * ~ * ~ * ~ * ~ DO NOT ADD MISC COMPONENT CODE HERE * ~ * ~ * ~ * ~ *
*   ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ * ~ *
*
*   only code for real html elements lives here, ie <hr> (NOT .dropdown, etc)
*/


// CODE BLOCKS

code {
	padding: 1px 3px;
	margin: -1px 0;
	border: none;
	color: @text-color-primary;
	border-radius: 2px;
	background: @smoke-96;
	font-weight: 500;
	white-space: pre-wrap;
}


// HORIZONTAL ROWS

hr {
  border: none;
  height: 1px;
  margin: 1em 0;
  background: @separator-color;

	&.hr-spaced {
		margin: 2em 0;
	}
}


// IMAGES

img {
	max-width: 100%;
}

canvas {
  image-rendering: optimizeSpeed;             /* Older versions of FF          */
  image-rendering: -moz-crisp-edges;          /* FF 6.0+                       */
  image-rendering: -webkit-optimize-contrast; /* Safari                        */
  image-rendering: -o-crisp-edges;            /* OS X & Windows Opera (12.02+) */
  image-rendering: pixelated;                 /* Awesome future-browsers       */
  -ms-interpolation-mode: nearest-neighbor;   /* IE                            */
}

iframe {
	border: none;
	max-width: 100%;
}
