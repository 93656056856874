
.media-box-title {
	font-size: @font-size-l;
	color: @text-color-primary;
  margin: 0 0 .2em 0;
}

.media-box-sub {
	font-size: @font-size-m;
	color: @text-color-secondary;
  margin: 0 0 .2em 0;
	font-weight: 500;
}
