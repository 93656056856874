.StripeElement {
  padding: 0 16px;
  height: 40px;
  width: 100%;
}

.StripeElement iframe {
  height: 100% !important;
}

.StripeElement > div {
  height: 100%;
}

.StripeElement--empty {
}

.StripeElement--complete {
}

.StripeElement--focus {
  border-color: #1e7ddc;
}
