/*
*	LOADS
*
*	Styles for loads
*
*/


// LOAD TABLES

.delivery-icon {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    line-height: 24px;
    text-align: center;

    svg {
        margin: 4px;
        .transform( rotate(-45deg) );
    }

    &.incoming {
        //background: @smoke-90;
        box-shadow: inset 0 0 0 2px @smoke-40;

        svg path {
            fill: @smoke-40;
        }

    }

    &.outgoing {
        box-shadow: inset 0 0 0 2px @smoke-40;
        .transform( rotate(-90deg) );

        svg path {
            fill: white;
            fill: @smoke-40;
        }
    }

    &.transfer {
        background: white;
        box-shadow: inset 0 0 0 2px @smoke-40;
        .transform( rotate(45deg) );

        svg path {
            fill: @smoke-40;
        }
    }
}


// LOAD LISTS

.load-list {
    margin: 0 0 1em;
}
.load-block {
    position: relative;
    border: 1px solid @separator-color;
    border-left: none;
    padding: .5em 1em;
    width: 100%;
    display: block;
    margin-bottom: .5em;
    cursor: pointer;
    max-width: 520px;

    &:hover {
        text-decoration: none;
        background: @smoke-98;
    }
    .indicator {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
    }
    .amount,
    .unit,
    .commodity {
        color: @text-color-primary;
        font-size: @font-size-m;
        font-weight: 500;
        display: inline-block;
    }
    .origin,
    .arrow,
    .destination {
        color: @text-color-secondary;
        font-size: @font-size-s;
        font-weight: 500;
        display: inline-block;
    }
}

.load-button {
    display: none;

    @media only screen and (max-width: 62.5rem) {
        display: unset;
        float: right;
    }
}


// LOAD ROW
.load-row-list {
}

.load-row {
    margin: -1em 0 1em;
    cursor: pointer;
    padding: 1em 0;
    border-bottom: 1px solid @separator-color;
    font-weight: 500;
    line-height: 20px;

    .date {
        color: @text-color-secondary;
    }
    .amount {
        font-size: @font-size-l;
    }
    .crop {

    }
    .source-destination {
        .to {
            color: @text-color-secondary;
        }
    }

}
