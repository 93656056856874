/*
*	TOOLTIP
*
*	Styles for little popups that appear on hover
*
*/

// FL-UI Tooltip
.Tooltip {
  padding: 0.5em;
  border-radius: 3px;
  background: @smoke-20;
  font-size: @font-size-s;
  max-width: 230px;
  color: white;
  text-align: center;
  font-weight: 500;

  &:before {
    content: "";
    position: absolute;
    border-style: solid;
    border-color: @smoke-20 transparent;
    // background: initial;
    // display: block;
    width: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
    border-width: 10px 10px 0px;
    top: auto;
  }
}

.Tooltip.bottom-right {
  &:before {
    left: auto;
    right: 20px;
    transform: none;
    bottom: -10px;
    top: auto;
  }
}

.Tooltip.top-center {
  &:before {
    border-top: 0px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid @smoke-20;
    left: 50%;
    transform: translateX(-50%);
    bottom: auto;
    top: -10px;
  }
}

.Tooltip.middle-left {
  &:before {
    border-top: 5px solid transparent;
    border-right: 10px solid @smoke-20;
    border-bottom: 5px solid transparent;
    left: -20px;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}

.tooltip-trigger:hover {
  cursor: pointer;
}

// Legacy Tooltip
.tooltip {
    font-weight: bold;
}

.fl-tooltip:hover {

    &:after {
        position: absolute;
        display: block;
        width: auto;
        height: auto;
        background: @clear-smoke-80;
        content: attr(data-tooltip-text);
        min-width: 40px;
        min-height: 30px;
        line-height: 30px;
        border-radius: 3px;
        top: -34px;
        left: 50%;
        margin: 0;
        .transform( translateX(-50%) );
        color: white;
        font-weight: 500;
        font-size: @font-size-s;
        z-index: 500;
        text-shadow: none;
        text-align: center;
        padding: 0 .75em;
        white-space: nowrap;

        // move mouse hover state past tooltip onto whatever's beneath
        // so you can move the mouse around w/o getting "caught" on a tooltip
        pointer-events: none;

        text-transform: none;
        letter-spacing: 0;
    }

    &:before {
        .before-after();
        border-style: solid;
        border-width: 4px 4px 0 4px;
        top: -4px;
        left: 50%;
        margin: 0 0 0 -4px;
        border-color: @clear-smoke-80 transparent;
    }

}
@media print {
  .Tooltip {
    display: none;
  }
}
