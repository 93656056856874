.search {
	height: 34px;
	transition: all 0.2s ease-in-out;
	position: relative;

	.clear-icon {
		border-radius: 30px;
		line-height: 1;
		padding: 5px;
		position: absolute;
		cursor: pointer;
		right: 4px;
		top: 4px;
		img {
			width: 17px;
		}
	}

	.search-icon {
		border-radius: 30px;
		cursor: pointer;
		line-height: 1;
		padding: 5px;
		position: absolute;
		left: 4px;
		top: 4px;
		img {
			width: 17px;
		}
	}

	.search-input {
		border: 1px solid rgb(230, 232, 235);
		line-height: 16px;
		padding: 8px 30px;
		color: @text-color-primary;
		width: 100%;
		opacity: 1;
		z-index: 30000;
	}
}
