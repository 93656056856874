// vendor styles
// react-select overrides so that input matches height of our <Input/> component

.Chooser,
.Select-control,
.Select.Chooser {
  min-height: 40px;
}
.Chooser .Select-placeholder,
.Select .Select-placeholder {
  line-height: 40px;
}

.leaflet-pm-toolbar .leaflet-pm-icon-trash {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIuMjUgNC41SDMuNzVIMTUuNzUiIHN0cm9rZT0iIzQ1NEE1NCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNiA0LjVWM0M2IDIuNjAyMTggNi4xNTgwNCAyLjIyMDY0IDYuNDM5MzQgMS45MzkzNEM2LjcyMDY0IDEuNjU4MDQgNy4xMDIxOCAxLjUgNy41IDEuNUgxMC41QzEwLjg5NzggMS41IDExLjI3OTQgMS42NTgwNCAxMS41NjA3IDEuOTM5MzRDMTEuODQyIDIuMjIwNjQgMTIgMi42MDIxOCAxMiAzVjQuNU0xNC4yNSA0LjVWMTVDMTQuMjUgMTUuMzk3OCAxNC4wOTIgMTUuNzc5NCAxMy44MTA3IDE2LjA2MDdDMTMuNTI5NCAxNi4zNDIgMTMuMTQ3OCAxNi41IDEyLjc1IDE2LjVINS4yNUM0Ljg1MjE4IDE2LjUgNC40NzA2NCAxNi4zNDIgNC4xODkzNCAxNi4wNjA3QzMuOTA4MDQgMTUuNzc5NCAzLjc1IDE1LjM5NzggMy43NSAxNVY0LjVIMTQuMjVaIiBzdHJva2U9IiM0NTRBNTQiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTcuNSA4LjI1VjEyLjc1IiBzdHJva2U9IiM0NTRBNTQiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTEwLjUgOC4yNVYxMi43NSIgc3Ryb2tlPSIjNDU0QTU0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=")
}
