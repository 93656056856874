/*
* CHOOSER
*
* Styles for fl-chooser, the input picker that replaced the select2 library
*
*/

.fl-autocomplete {
  position: relative;
  display: block;
  font-weight: 500;

  .results {
    position: absolute;
    border: 1px solid @smoke-80;
    box-shadow: 0 4px 8px @clear-smoke-20;
    z-index: 10000;
    border-radius: 0px;
    background: white;
    width: 100%;

    .empty {
      background: @smoke-93;
      text-align: center;
      width: 100%;
      color: @text-color-secondary;
      padding: 3em 0;
      border-bottom: 1px solid @separator-color;
    }
    .result-list {
      max-height: 230px;
      overflow-y: auto;
      border-bottom: 1px solid @separator-color;
      width: inherit;

      .result-header {
        background: @smoke-93;
        color: @text-color-secondary;
        text-transform: uppercase;
        font-size: @font-size-s;
        font-weight: bold;
        padding: 0 1em;
        //margin: 1em 0 0;
      }
      &.with-images .result {
        .title,
        .sub {
          margin-left: 40px;
        }
      }
    }
  }

  .hide {
    display: none;
  }

  .result {
    padding: .5em 1em;
    cursor: pointer;
    position: relative;
    overflow: auto;
    min-height: 30px;
    width: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.add-new:not(:only-child) {
      border-top: 1px solid @separator-color;
    }

    &.selected:after {
      .before-after();
      width: 16px;
      height: 16px;
      margin: -8px 0 0 0;
      top: 50%;
      right: 16px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAA40lEQVRYR+2Wyw6EIAxF9cvH+fMZDCXV9HFbAmxwQxTiOZZL43ksvs7F/GMLzKzAVbebxvt2lkCBfqrA9z82iRkCHE6ZbxKjBST4Q2KkgAUvEncVRglAcC2EYloDDQuGSwJqWkGBEPwtYKYVEAjDuYCbVkcgBUcFWmIViTQc2QLOfHSwOtEF90IofTCX6IZbx5D6tiZRnntr6Dib8dEakfd11kulrVLXW50wIxGCa1vAbSMSYTgiUNYgEik4KuBJpOERAU2iCx4VeEt0wzMCJMFH85x7k6N+SDxum98CuwLLK/ADHfE4IYm3gh8AAAAASUVORK5CYII=);
      background-size: cover;
      opacity: .5;
    }

    &.secondary {
      background: @smoke-96;

      &:hover,
      &:focus {
        background: @smoke-94;
      }
    }

    &.highlighted {
      background: @blue;
      color: @white;

      &:hover {
        background: @dark-blue;
      }
    }

    &:hover,
    &:focus {
      background: @smoke-98;
    }

    // contents
    .icon {
      width: 30px;
      height: 30px;
      float: left;
      border-radius: 3px;
      background: @smoke-90;
      margin: 4px 1em 4px 0;
    }
    .title {
      font-size: @font-size-l;
      color: @text-color-primary;
    }
    .sub {
      font-size: @font-size-m;
      color: @text-color-secondary;
    }
  }
}
