/*
*	SETTINGS
*
*	Styles for the settings page
*
*/

ul.settings-tabs {
  border-bottom: 1px solid hsl(216, 10%, 90%);
}

.flow-status-indicator {
    height: 1.5em;
    width: 1.5em;
    float: left;
    margin: 0.75em;
    margin-left: 0;
    border-radius: 50%;
    &.collecting-data {
        background-color: @green;
    }
    &.never-connected {
        background-color: @smoke-90;
    }
}

.settings-content .heading-underline {
  border-bottom: none !important;
  .title {
    margin: 0 0 10px 0;
  }
}

.settings-content .heading-info {
  @media only screen and (min-width: @screen-md-min) {
    float: right;
  }
  line-height: 26px;
}
