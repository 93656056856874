.map-image-overlay,
.map-image-backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.map-image-overlay {
  z-index: 101;
}

.map-image-overlay img {
  width: 100%;
  height: 100%;
}

.map-image-backdrop {
  background: @clear-smoke-60;
}
