/*
*	BLANK SLATES
*
*	Styles for when there's nothing else to show
*
*/


// BASIC
// basic, simple blank slate for most use cases

.blank-slate {

	// plain white blank slate
	display: block; // some are <a> elements
	color: @text-color-primary;
	padding: 40px;
	text-align: center;

	// dotted line that wants to be clicked and "filled in"
	&.blank-slate-dotted {
		.ui-control();
		border: 2px dashed @separator-color;
		font-size: @font-size-l;
		color: @text-color-secondary;
		border-radius: 2px;
		margin: 0 0 1em;
	}
}


// FULL PAGE
// big blank slates with illustrations on "main" pages

.blank-slate-contain {
	text-align: center;
	background: url("../../../assets/images/blank_slate/perspective-line.png") no-repeat;
	height: 100%;

	&.budget {
		// background-position: center 150px;
		background: none;

		img {
			width: 154px;
			padding: 80px 0 0 0;
			margin: 0 0 20px 0;
		}

	}

	&.fields {
		background-position: center 135px;

		img {
			width: 401px;
			padding: 80px 0 0 0;
			margin: 0 0 20px 0;
			position: relative;
			right: 0px;
		}

	}

	&.activity {
		background: none;

		img {
			width: 566px;
			padding: 80px 0 0 0;
		}

	}

	&.yield {
		background-position: center 206px;

		img {
			width: 423px;
			padding: 90px 0 0 0;
			margin: 0 0 30px 0;
		}
	}

	&.inventory {
		background-position: center 164px;

		img {
			width: 340px;
			padding: 80px 0 0 0;
			margin: 0 0 20px 0;
		}
	}

	&.equipment {
		background-position: center 230px;

		img {
			width: 296px;
			padding: 80px 0 0 0;
			margin: 0 0 20px 0;
		}
	}

	&.notes {
		background: none;

		img {
			width: 315px;
			padding: 80px 0 0 0;
			margin: 0 0 10px 0;
		}
	}
}

.blank-slate-advantage {
	padding: 1em 0em;

	p {
		max-width: 400px;
		margin-top: 1em;
	}

	img {
		display: block;
	  margin: 0 auto;
	}

	.chi-col {
		padding-top: 2em;
	}

	.nmon-col {
		padding-top: 3em;
	}

	.upgrade-link {
		margin-bottom: 10px;
	}
}

@media print {
    .blank-slate {
        border: none;
    }
}
