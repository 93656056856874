/*
*	UTILITIES
*
*	Styles for quick one-off things like hiding and floating elements.
*   Classes should be named almost exactly after what they do.
*
*   Better to avoid this if you can build it into a component class, but
*   these are better than making up a new class just to use "float: right"
*
*/


// LAYOUT

// display
.display-none {
	display: none !important;
}
.display-block {
	display: block !important;
}
.display-inline {
	display: inline !important;
}
.display-inline-block {
	display: inline-block !important;
}

// align
.vertical-align-middle {
	vertical-align: middle;
}

// padding

.padding-bottom-10 {
  padding-bottom: 10px
}

// margin

.margin-top-10 {
  margin-top: 10px
}

// Small screen scroll
.narrow-parent {
  width: 100%;
  overflow-x: scroll;
}

.narrow-child-600 {
  width: 600px;
}


@media only screen and (max-width: @screen-md-min) {
  .horizontal-scrollable-parent {
    width: 100%;
    overflow-x: scroll;

    li {
      display: inline-block;
    }
  }

  // Loops over desired widths - set arbitrarily
  @start-width: 200;
  @end-width: 1200;
  .loop-scroll-sizes(@size) when (@size <= @end-width) {
    .horizontal-scrollable-child-@{size} {
      width: (@size * 1px) !important;
    }

    .loop-scroll-sizes(@size + 100)
  }

  .loop-scroll-sizes(@start-width);
}


// position
.position-absolute {
    position: absolute;
}
.position-relative {
    position: relative;
}

// floats
.float-right {
       float: right;
}
.float-left {
       float: left;
}
.clearfix:after {
  content:"";
  display:table;
  clear:both;
}


// TYPE

// text alignment
.text-left {
	text-align: left;
}
.text-centered {
	text-align: center;
}
.text-right {
	text-align: right;
}

// font sizes
.font-size-xxl { font-size: @font-size-xxl; }
.font-size-xl  { font-size: @font-size-xl; }
.font-size-l   { font-size: @font-size-l; }
.font-size-m   { font-size: @font-size-m; }
.font-size-s   { font-size: @font-size-s; }
.font-size-xs  { font-size: @font-size-xs; }

// font weights
.font-weight-700 { font-weight: 700; }
.font-weight-500 { font-weight: 500; }
.font-weight-300 { font-weight: 300; }

// text colors
.text-color-primary   { color: @text-color-primary; }
.text-color-secondary { color: @text-color-secondary; }
.text-color-tertiary  { color: @text-color-tertiary; }

// Spacing
@spacing-xs: 0.25rem; // 3.5px
@spacing-sm: 0.5rem; // 7px
@spacing:    1rem;   // 14px
@spacing-lg: 1.5rem; // 21px

.spacing {
  margin-left:  @spacing;
  margin-right: @spacing;
}

.spacing-right {
  margin-right: @spacing;
}

.spacing-left {
  margin-left: @spacing;
}

.spacing-bottom {
  margin-bottom: @spacing-xs;
}

// MISC

// opacity
.faded {
	opacity: .5;
}

.no-min-width {
  min-width: 0px !important;
  max-width: 100% !important;
}

// PRINT STYLES

@media print {
	.do-not-print {
		display: none !important;
	}
}
