/*
*	MODAL
*
*	Styles for modal windows
*
*/

@modal-padding: 1em 1.5em;

.fl-separator {
	border-bottom: 1px solid @separator-color;
}

// invisible wrapper for modals
// contains the modal window and the backdrop
.fl-modal-wrapper {
	position: fixed;
	background: @clear-smoke-50;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 4000;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	overflow-y: scroll;

	&.field-search {
		background: @clear-green-90;
	}

	&.initial-acreage {
		background: url('../../../assets/images/onboarding/green-farmland.jpg');
		background-size: cover;
	}
}

// the modal window itself
// should centered inside the browser viewport
.fl-modal-window {
	position: relative;
	color: @text-color-primary;
	display: block;
	overflow: hidden;
	background: white;
	box-shadow: 0 4px 10px @clear-smoke-20;
	border-radius: 2px;
	margin-top: 4em;
	margin-bottom: 4em;
	width: 500px; // can make this smaller later
	max-width: 100%; // shrink on mobile devices
	min-width: min-content;
	z-index: 1; // push above backdrop

	// center vertically
	/* breaks scrolling
	top: 50%;
	-ms-transform: translate(0,-50%);
	-webkit-transform: translate(0,-50%);
	transform: translate(0,-50%);
	*/

	// center vertically (kindof)
	// temporary class (until we build better solution)
	// used to vertically center shorter modals that have fixed height
	&.center-vertically {
		margin-top: 15%;
	}

	&.modal-900 { width: 900px; }
	&.modal-800 { width: 800px; }
	&.modal-700 { width: 700px; }
	&.modal-600 { width: 600px; }
	&.modal-500 { width: 500px; }
	&.modal-400 { width: 400px; }
	&.modal-300 { width: 300px; }


	// Generic modal section
	.fl-modal-section {
		padding: 0 @modal-padding;
		.fl-separator();
	}

	.fl-modal-scroll {
		position: relative;
		padding: 0 @modal-padding;
		height: 480px;
		overflow-y: scroll;
		.fl-separator();
	}

	// modal header
	// a title, etc, sometimes the close "x"
	.fl-modal-header {
		padding: @modal-padding;
		.fl-separator();
		position: relative; // contain close-x's when vertically centered

		h1, h2, h3, h4, h5, h6 {
			margin: 0 3em 0 0; // room for close-x
			line-height: 1;
		}

		// big header
		// for ex: crop health promo modal
		&.feature-header {
			padding: 2em;
			text-align: center;
			color: @text-color-primary;

			h1, h2, h3, h4, h5, h6 {
				margin: .5em;
				font-weight: 400;
			}

			p {
				text-align: center;
				font-weight: 500;
				margin: 1em auto;
				max-width: 30em;
			}

		}

	}

	// modal body
	// most of the modal content
	.fl-modal-body {
		position: relative;
		padding: @modal-padding;
		.fl-separator();

		// don't affect styles for headings inside callouts
		// and other non-direct descendents of the modal body
		& > h1,
		& > h2,
		& > h3,
		& > h4,
		& > h5,
		& > h6 {
			margin: .5em 0 .5em;
		}

		&.fl-modal-body-no-padding {
			padding: 0;
		}
	}

	// Align validation errors when folloing appended units div
	// Only unsed in modals
	.js-help-inline-append {
		display: inline-block;
	}

	// modal footer
	// save/cancel buttons etc
	.fl-modal-footer {
		padding: @modal-padding;
		border-top: 1px solid @separator-color;
		text-align: right;

		// action on the left of the modal for deletes etc
		.left {
			font-weight: 500;
			color: @text-color-primary;
			float: left;
			line-height: 34px;
			cursor: pointer;
			padding-left: 1em;
			&:hover {
				text-decoration: underline;
			}
		}

	}

	.fl-modal-footer-button a {
		display: block;
		color: @blue;
		padding: @modal-padding;
		text-align: center;
	}

	.fl-modal-no-separator {
		border-bottom: none;
	}

	// Modal ribbon for promotional popups
	.modal-ribbon {
		width: 50%;
		padding: 0.5em;
		background: @yellow;
		margin-left: -35px;
		text-transform: uppercase;
		position: relative;
		font-weight: 500;
		line-height: 1em;

		// Use before and after for the triangles at the end of the flag
		&:after {
			.before-after();
			border-right: .7em solid transparent;
			border-bottom: 1em solid @yellow;
			right: -.7em;
			bottom: 0;
		}
		&:before {
			.before-after();
			border-right: .7em solid transparent;
			border-top: 1em solid @yellow;
			right: -.7em;
			top: 0;
		}
	}

} // end modal-window



// SPECIAL CASES
// lightboxes, slideshow nav, etc

// slideshow nav
.fl-modal-nav-item {
	position: absolute;
	top: 50px;
	width: 60px;
	font-size: 100px;
	font-weight: 100;
	color: white;
	height: 100px;
	line-height: 100px;
	cursor: pointer;
	text-align: center;
	opacity: .8;

	&:hover {
		opacity: 1;
	}

	&.prev {
		left: -60px;
	}
	&.next {
		right: -60px;
	}

}

// radio button and chooser in modal margins
.radio-margin {
	margin: 5px;
}
.chooser-margin {
	margin-left: 15px;
}
.submit-button-margin {
	margin: 25px auto;
}

// image in modal
// ex. how to draw a field

.fl-modal-img-contain {
	text-align: center;

	img {
		margin: .5em 0 2em 0;
	}
}

// lightbox-style modal
.light-box {
	img,
	video {
		background: blue;
		min-width: 500px;
		min-height: 300px;

		//width: 10000px;
		//height: 10000px;

		max-width: 90%;
		max-height: 90%;

	}
}

// the picture or video inside the lightbox
.fl-modal-lightbox-media {
	width: 100%;
	min-height: 200px;
	background: @smoke-90;
	margin: 0 auto;
	display: block;
}


// actions like deleting the image in the modal

.fl-modal-lightbox-action {
	.small-caps-heading();
	text-align: center;
	text-align: right;
	padding: 1em;
	//cursor: pointer;

	.icon {
		width: 16px;
		height: 16px;
		opacity: .5;
		cursor: pointer;
		&:hover {
			opacity: .7;
		}
	}
}

.inline-confirm-action {
	margin: 0 0 0 .5em;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

// form/table line heights
.line-height-25 {
	line-height: 25px;
}

// LOAD EDIT MODAL

.load-modal-body {
	.load-contents {
		padding: 2em;
		margin: -20px -19px 20px;
		text-align: center;
		position: relative;
		border-bottom: 1px solid @separator-color;
		line-height: 4; // fix for line break issue with modal

		.chooser-placeholder-container {
			display: inline-block;
		}
		// big select2
		.select2-container {
			min-width: 10em;
		}
		.select-box-placeholder {
			font-size: @font-size-xl;
			display: inline-block;
		}
	}
}

// This ensures that the body cannot scroll while the modal is open.
// The modal component itself takes care of adding/removing the class.
body.fl-modal-present {
	overflow: hidden;
}

// Mobile modal styles

@media only screen and (max-width: @screen-md-min) {
	.fl-modal-nav-item {
		background-color: rgba(0, 0, 0, .5);
		line-height: 60px;
		width: 50px;
		height: 80px;
		top: 80px;

		&.next {
			border-radius: 1000px 0 0 1000px;
			padding-left: 10px;
			right: 0;
		}
		&.prev {
			border-radius: 0 1000px 1000px 0;
			padding-right: 10px;
			left: 0;
		}
	}
}

@media only screen and (max-width: 30em) {

	.fl-modal-window {
		margin-top: 0;
		margin-bottom: 0;
		width: 100%;
		// min-height: 100%;
	}
}

@media only screen and (max-width: 30em) {

	.fl-modal-window {
		.fl-modal-body {
			padding-left: .5em;
			padding-right: .5em;
		}
	}

	.form-horizontal {
		.controls {
			display: inline-block;
			margin-left: 0px;
			max-width: 65%;
			width: 100%;
		}

		.control-label {
			width: 115px;
			max-width: 35%;
			text-align: left;
		}
	}

	// Specific modals
	.load-modal-body .load-contents {
		margin: 0 0 1em 0;
	}
}
