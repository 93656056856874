/*
*	TRI-BADGE
*
*	Similar to big stats, a banner at the top of the page that shows big numbers
*
*/


.tri-badge {
    margin: 0 0 2em;
    width: 100%;

    &.tri-badge-rainfall tr.stats td {
        color: @blue;
    }

    tr.stats {
        text-align: center;
        background: @smoke-97;

        td {
            width: 33%;
            color: @text-color-primary;
            padding: 14px 0;

            span.rain-blank {
                font-size: @font-size-s;
                padding-top: 8px;
            }

            .amount {
                font-weight: normal;
                font-size: @font-size-xxl;
                margin-bottom: 6px;
            }

            .rain-label {
                font-size: @font-size-xs;
                text-transform: uppercase;
                letter-spacing: .05em;
                font-weight: bold;
                color: @text-color-secondary;
                line-height: 1;
            }

            &.day {
                border-right: 1px solid @separator-color;
            }

            &.week {
                border-right: 1px solid @separator-color;
            }

        }

    }

}
