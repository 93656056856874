/*
*	ICONS
*
*	Styles for icons throughout the app
*
*/

.svg-icon-success,
.svg-icon-warning,
.svg-icon-error {
    width: 16px;
    height: 16px;
    margin: 0px 1em -4px 0;
}
.svg-icon-success {
    stroke: @green;
    fill: @green;
}
.svg-icon-warning {
    stroke: @orange;
    fill: @orange;
}
.svg-icon-error {
    stroke: @red;
    fill: @red;
}

