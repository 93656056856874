/*
*	ICON PICKER
*
*	Styles for picking icons for things like tractors and people
*
*/


// ICON PICKER
.resource-icon-picker {
	hr {
		margin: 10px 0;
	}
}

// CHOOSE YOUR ICON FROM A LIST

.default-icons-wrapper {
	overflow: auto;

	.default-icon {
		width: 80px;
		height: 80px;
		margin: 2px 4px 4px 2px;
		border: 1px solid @smoke-80;
		float: left;
		cursor: pointer;
		border-radius: 2px;

		&:hover {
			border-color: @smoke-60;
			.box-shadow( 0 0 0 2px @smoke-90 );
		}

		&.selected {
			border-color: @text-color-primary;
			.box-shadow( 0 0 0 2px @smoke-60 );
		}
	}
}

// UPLOAD YOUR OWN

.custom-icon-wrapper {
	overflow: auto;

	.custom-icon {
		width: 120px;
		height: 120px;
		margin: 0 10px 10px 0;
		border-radius: 2px;
		float:left;
		clear: both;
	}

	.btn {
		float:left;
		clear: both;
	}

}
