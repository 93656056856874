.carousel {
  position: relative;
  display: flex;
  height: inherit;
  justify-content: space-between;
  align-items: stretch;
}

.carousel__slider {
  flex-grow: 2;
  height: inherit;
  padding-bottom: 40px;
}
.carousel__slider-tray-wrapper {
  height: inherit;
}

.carousel__inner-slide {
  height: inherit;
}

.carousel__next-button,
.carousel__back-button {
  background-color: white;
  border: 0;
  box-shadow: none;
  color: #1e7ddc;
  outline: none;
}
.carousel__next-button:disabled,
.carousel__back-button:disabled {
  color: hsl(220, 20%, 90%);
}
.carousel__next-button:focus,
.carousel__back-button:focus {
  outline: none;
}
.carousel__next-button svg,
.carousel__back-button svg {
  max-width: 24px;
  max-height: 24px;
}
.carousel__next-button {
  padding: 0 0 0 8px;
}
.carousel__back-button {
  padding: 0 8px 0 0;
}

.carousel__dot-group {
  position: absolute;
  width: auto;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}
.carousel__dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid hsl(220, 20%, 70%);
  border-radius: 100%;
  margin: 0 4px;
  padding: 0;
}
.carousel__dot--selected {
  background-color: hsl(220, 20%, 70%);
}
