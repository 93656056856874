/*
*   COMMODITY FILTER
*
*   Styles for the commodity filter in the sidebar
*
*/


.commodity-filter {
    margin: 0;
    list-style: none;

    // double styles so it cascades correctly
    &.disabled .commodity-pill.inactive,
    &.disabled .commodity-pill.active {
        color: @smoke-70;

        .change-crop {
            display: none !important;
        }
    }

    &.collapsed {
        .commodity-pill {

            // hide unselected pills with css
            // so we can animate them open
            &.inactive {
                height: 0px;
                margin: 0;
            }
            &.active {
                .text-container,
                .change-crop,
                .close-x {
                    opacity: 1;
                    display: block;
                }
            }
        } // end commodity-pill
    } // end collapsed
}

@pill-height: 34px;

.commodity-pill {
    .ui-control();
    position: relative;
    display: block;

    margin: 0 0 6px;

    line-height: @pill-height;
    height: @pill-height;

    border-radius: 3px;
    text-shadow: none;

    overflow-y: hidden;

    opacity: .9;

    -webkit-transition: height .2s ease;
    -moz-transition: height .2s ease;
    transition: height .2s ease;

    &:hover {
        opacity: 1;
    }

    &.all-crops {
        box-shadow: inset 0 0 0 1px @smoke-90;
        color: @text-color-primary;
    }

    .text-container,
    .change-crop,
    .close-x {
        position: absolute;
        top: 0;
        height: @pill-height;

        -webkit-transition: opacity .2s ease;
        -moz-transition: opacity .2s ease;
        transition: opacity .2s ease;
    }
    .change-crop,
    .close-x {
        // hide by default; opacity is 0 so it can animate in
        opacity: 0;
    }
    .text-container {
        bottom: 0;
        left: 0;
        right: @pill-height;

        font-size: @font-size-l;
        font-weight: 500;

        padding: 0 0 0 1em;
        text-overflow: ellipsis;

    }
    .change-crop {
        right: 0;
        font-size: @font-size-s;
        line-height: @pill-height;
        padding: 0 1em;
    }
    .close-x {
        right: 0;
        width: @pill-height;
        padding-bottom: 2px; // vertical alignment
        border-radius: 0;

        text-shadow: none;
        font-weight: 500;
        text-align: center;

        &:hover {
            background: hsla(0, 0%, 100%, .1);
            background: transparent;
        }

        &.close-x-center {
            padding-top: 10px;
        }
    }
}
