.fl-icon {
  vertical-align: middle;
}

.fl-icon:after {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
}

.fl-icon-success:after {
  background-image: url('../../../assets/images/icons/circle_check.svg');
}

.fl-icon-error:after {
  background-image: url('../../../assets/images/icons/circle_x.svg');
}
