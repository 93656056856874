/*
*	NOTES
*
*	Global styles for notes w/ pictures, text, location
*
*/


// NOTE INDEX

@note-border-radius: 2px;

.note-list {
	//margin: -27px -37px 0 0;

	.note-item {
		// width: 400px;
		// width: 46%;

		vertical-align: top;
		display: block;

		// margin: 0 1.5em 2em 0;
		padding: 1.5em;

		border: 1px solid @separator-color;
		box-shadow: 0 2px 4px @clear-smoke-05;

		cursor: pointer;

		border-radius: @note-border-radius;

		&:hover {
			text-decoration: none;
		}

		// MAPS AND IMAGES
		.maps-and-images {
			height: 160px;
			margin: -20px -20px 20px;
			overflow: hidden;
			background: @smoke-90;
			position: relative;
			//border-radius: @note-border-radius @note-border-radius 0 0;

			// WRAPPERS - basics
			.maps,
			.images {
				position: relative;
				display: block;
				float: left;
				width: 100%;  // default
				height: 160px; // default
			}
			.maps {
				// center icon
				.note-icon {
					position: absolute;
					left: 50%;
					top: 50%;
					margin: -4px 0 0 -4px;
				}
			}

			// WRAPPERS - sizing and hiding

			// if no map
			&.no-map {
				&.no-image {
					display: none; // hide the whole thing
				}
				&.has-image {
					.maps {
						display: none; // just hide the map
					}
				}
			}

			// if there is a map
			&.has-map {
				&.no-image {
					.images {
						display: none; // just hide the images
					}
				}
				&.has-image {
					.maps    { width: 50%; } // split the space between the two
					.images { width: 50%; }
				}
			}

			// CHILDREN - basics

			.map,
			.image {
				display: block;
				float: left;
				background-size: cover;
				background-position: center;
			}

			// CHILDREN - sizing

			// map sizing
			// there's only one, so...
			.map {
				width: 100%;
				height: 100%;
			}

			// image sizing
			&.num-pics-one .image {
				width: 100%;
				height: 100%;
			}
			&.num-pics-two .image {
				width: 50%;
				height: 100%;
			}
			&.num-pics-three .image {
				width: 33%;
				height: 100%;
			}
			&.num-pics-four .image,
			&.num-pics-five .image,
			&.num-pics-six .image,
			&.num-pics-more  .image {
				width: 50%;
				height: 50%;
			}
		}

		// OTHER STUFF
		.text {
			font-size: @font-size-l;
			color: @text-color-primary;
			font-weight: 400;
			margin: 0 0 .5em;
			max-height: 80px;
			overflow: hidden;
		}
		.meta {
			font-size: @font-size-m;
			font-weight: 500;
			color: @text-color-secondary;
		}
	}
}

// NOTE DETAILS
.note-textarea {
	font-size: @font-size-l;
	min-height: 6em;
	width: 100%;
	padding: .5em;
	font-weight: 400;
	color: @text-color-primary;
}

// ON THE MAP
.note-icon {
	width: 8px;
	height: 8px;
	border-radius: 100px;
	box-shadow: inset 0 2px 1px white, 0 1px 2px @clear-smoke-30, 0 0 0 4px hsla(0,0%,100%,.3);
	margin: 11px;
	background: @smoke-90;

	opacity: .9;
	&:hover {
		opacity: 1;
	}

}
.note-body {
	font-size: @font-size-l;
	margin: 0 0 .5em !important;
	color: @text-color-primary;
	font-weight: 400;
}

// image thumbnail on map
.map-note-image {
	border: 2px solid white;
	border-radius: 2px;
}
