/*
*	ACTIVITY STREAM
*
*	Styles for activity streams
*
*/

@activityPadding: 16px;

.activity-stream {
	margin:0 0 2em;
	clear:both;
    color: @text-color-primary;

	&>li {
		overflow:auto;
	    background:white;

	    a {
            display:block;
            cursor:pointer;
            margin:0;
            padding:0;
            overflow: auto;
            width: 100%;
            height:auto;
            position: relative;

            .indicator {
                width: 8px;
                height: 8px;
                position: absolute;
                left: 15px;
                top: 10px;
                background: @smoke-80;
                border-radius: 40px;
                .box-shadow(0 0 0 2px white);
            }

    	    &:hover {
    	        text-decoration:none;
    	    }

    		img.icon {
    			float: left;
    			height: 30px;
    			width: 30px;
    			margin-right: 1em;

    			&.check {
    				height: 18px;
    				width: 18px;
    				margin-top:6px;
    				margin-left:6px;
    			}
    		}

    		// necessary for indicator
    		.wrap {
    			overflow: auto;
    			margin:0 0 0 18px;
    			border-left:2px solid @separator-color;
    			font-size: @font-size-m;
    			line-height:1;
                padding-bottom: .5em;

    			&>*:last-child {
    			    margin-bottom:0;
    			}
    		}

    		// title
    		.title {
    		    margin: 8px @activityPadding 3px;

        		.activity-type {
        		    font-weight:bold;

        		}
        		.field {
        		}
        	}

    		// other info
    		.meta {
    		    margin: 0 @activityPadding .5em;
    			color: @smoke-70;
    			font-size: @font-size-s;
                font-weight: 500;
    		}

    		// notes
    		.notes {
    		    margin: 0 @activityPadding .5em;
                color: hsl(0, 0%, 40%);
                font-style: italic;
                font-size: @font-size-m;
                line-height: 20px;
    		}

    		// pics
    		.photos {
    		    margin: 0 @activityPadding .5em;

    		    .photo {
        			float:left;
        			margin: 0 1em 1em 0;
        			border: 5px solid white;
        			width:100px;
        			height:auto;
                    .box-shadow( 0 1px 3px hsla(0, 0%, 0%, .2) );
        		}
    		}

	    } // end a
	} // end li
} // end ul
