/*
*	PROGRESS BAR
*
*	Styles for progress bars throughout the app.
*
*/


.progress {

    height: 8px;
    background: @clear-smoke-05;
    border-radius: 3px;
    margin: 0 0 1em;
    display: flex;

    .bar {
        background-color: @text-color-primary;
        border-radius: 3px;
        border-top: 0px solid @smoke-20;
        height: 100%;

        &.bar-smoke {
            background: @text-color-primary;
        }

        &.bar-green {
            background: @green;
        }

        &.bar-dark-yellow {
            background: darken(@yellow, 10%);
        }

        &.bar-light-yellow {
            background: lighten(@yellow, 10%);
        }

        &.bar-transparent {
            background: transparent;
        }
   	}

    &.progress-smoke {
        background: @smoke-92;

        .bar {
            background: @text-color-primary;
            float: left;
        }

    }
    &.active {
        .bar {
            background-color: @smoke-50;
            float: left;
        }
    }

    &.progress-blue {
        background: @smoke-80;

        .bar {
            background: @blue;
            float: left;
        }

    }
    &.active {
        .bar {
            background-color: @smoke-50;
            float: left;
        }
    }

    &.squared,
    &.squared .bar {
        border-radius: 0;
    }

    &.flat,
    &.flat .bar {
        .box-shadow(none);
        border: none;
    }

    &.no-bg {
        background: transparent;
    }

    &.progress-striped.active .bar {
        .animation(progress-bar-stripes 2s linear infinite);
        .transform(translate3d(0,0,0));
        background-size: 40px 40px;

        // thanks bootstrap
        background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, hsla(0, 100%, 100%, 0.5)), color-stop(0.25, hsla(0, 0%, 0%, 0)), color-stop(0.5, hsla(0, 0%, 0%, 0)), color-stop(0.5, hsla(0, 100%, 100%, 0.5)), color-stop(0.75, hsla(0, 100%, 100%, 0.5)), color-stop(0.75, hsla(0, 0%, 0%, 0)), to(hsla(0, 0%, 0%, 0)));
        background-image: -webkit-linear-gradient(45deg, hsla(0, 100%, 100%, 0.5) 25%, hsla(0, 0%, 0%, 0) 25%, hsla(0, 0%, 0%, 0) 50%, hsla(0, 100%, 100%, 0.5) 50%, hsla(0, 100%, 100%, 0.5) 75%, hsla(0, 0%, 0%, 0) 75%, hsla(0, 0%, 0%, 0));
        background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 75%, transparent);
        background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.5) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 75%, transparent);
        background-image: linear-gradient(45deg, hsla(0, 100%, 100%, 0.5) 25%, hsla(0, 0%, 0%, 0) 25%, hsla(0, 0%, 0%, 0) 50%, hsla(0, 100%, 100%, 0.5) 50%, hsla(0, 100%, 100%, 0.5) 75%, hsla(0, 0%, 0%, 0) 75%, hsla(0, 0%, 0%, 0));
    }

}
