/*
* GRID
*
* A grid system
*
*/

// # Example Usage
// <div class="row-fluid">
//     <div class="col xs-12 sm-6 md-3 lg-1">
//         ...
//     </div>
// </div>

.container, .container-fluid {
  margin: 0 1rem;
}

.clear-fix {
  .clear-fix();
}

// don't let columns overflow rows
.row,
.row-fluid {
  .clear-fix();
  margin: 0 -1rem;
}

.row-spaced {
  .clear-fix();
  margin: 2rem -1rem;
}

// float all columns together
.col {
  float: left;
  position: relative;
  display: inline-block;
  min-height: 1px;

  // GUTTERS
  // TODO: make it so the first and last columns aren't 1rem bigger than the others?
  padding: 0 1rem;

  &.np {
    padding: 0 0 0 0;
  }

}

// Screen breakpoints
@screen-xs-min: 0;
@screen-sm-min: 40em; // em - ~479.99px min-width
@screen-md-min: 60em; // em - ~799.99px min-width
@screen-lg-min: 75em; // em - ~1200px min-width

// Generate grid system
@grid-columns: 12;

.generate-columns(@screen-size; @width) {
  .row, .row-fluid {
    .@{screen-size}-@{width} {
      width: percentage((@width / @grid-columns));
    }
    .col.@{screen-size}-np {
      padding: 0;
    }
    .col.@{screen-size}-pad {
      padding: 0 1rem;
    }
    .col.@{screen-size}-pad-vert {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .col.@{screen-size}-np-vert {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.generate-block-columns(@screen-size; @width) {
  .@{size}-@{i} {
    display: block;
    width: 100%;
  }
}

.set-offset(@screen-size; @width) {
  .@{screen-size}-offset-@{width} {
    margin-left: percentage((@width / @grid-columns));
  }
}

.set-push(@screen-size; @index) {
  .@{screen-size}-push-@{index} {
    left: percentage((@index / @grid-columns));
  }
}

.set-pull(@screen-size; @index) {
  .@{screen-size}-pull-@{index} {
    right: percentage((@index / @grid-columns));
  }
}

.set-float(@screen-size) {
  .@{screen-size}-float-left {
    float: left;
  }
  .@{screen-size}-float-right {
    float: right;
  }
}

.block-grid-html-classes(@size, @i) {
  .@{size}-block-grid-@{i} {
    .block-grid(@i);
  }
}

.loop-block-columns(@size, @i: 1) when (@i =< @grid-columns) {
  .generate-block-columns(@size, @i);

  .loop-block-columns(@size, @i + 1)
}

.loop-columns(@screen-size; @i: 1) when (@i =< @grid-columns) {
  .generate-columns(@screen-size, @i);
  .set-offset(@screen-size, @i);
  .set-push(@screen-size, @i);
  .set-pull(@screen-size, @i);
  .set-float(@screen-size);
  .block-grid-html-classes(@screen-size, @i) ;

  .loop-columns(@screen-size; @i + 1)
}

.hidden-classes(@screen-size) {
  .@{screen-size}-hide {
    display: none !important;
  }
}

.mark-size(@screen-size) {
  body:after {
    content: "@{screen-size}";
    display: none;
  }
}

// Styles apply to everything bigger than specified size
@media only screen and (min-width: @screen-xs-min) {
  .loop-columns(xs);
  .mark-size(xs);
}
@media only screen and (min-width: @screen-sm-min) {
  .loop-columns(sm);
  .mark-size(sm);
}
@media only screen and (min-width: @screen-md-min) {
  .loop-columns(md);
  .mark-size(md);
}
@media only screen and (min-width: @screen-lg-min) {
  .loop-columns(lg);
  .mark-size(lg);
}

// Styles apply to everything smaller than specified size
@media only screen and (max-width: (@screen-sm-min - 1)) {
  .loop-block-columns(sm);
}
@media only screen and (max-width: (@screen-md-min - 1)) {
  .loop-block-columns(md);
}
@media only screen and (max-width: (@screen-lg-min - 1)) {
  .loop-block-columns(lg);
}

// Single size specific classes
@media only screen and (min-width: @screen-xs-min) and (max-width: @screen-sm-min) {
  .hidden-classes(xs);
}
@media only screen and (min-width: @screen-sm-min) and (max-width: @screen-md-min) {
  // Having padding problems on small sizes so overriding on small screens
  // .sm-12 {padding-left: 0; padding-right: 0;}

  .hidden-classes(sm);
}
@media only screen and (min-width: @screen-md-min) and (max-width: @screen-lg-min) {
  .hidden-classes(md);
}
@media only screen and (min-width: @screen-lg-min) {
  .hidden-classes(lg);
}

// # Block Grid
//
// Technique adapted from Foundation 5 for Bootstrap 3.0.3 to at least 3.3.1.
// https://github.com/zurb/foundation/blob/f755d8704123f86c281ede0b171881e2672f150d/scss/foundation/components/_block-grid.scss
//
// # Example Usage
//
// To produce a grid of 2 items per row on an extra-small screen, and 3 items
// per row on a small screen:
//
//     <div class="block-grid-xs-2 block-grid-sm-3">
//         <div class="block-grid-item">
//             ...
//         </div>
//     </div>

@grid-gutter-width: 1rem;
@grid-columns: 12;

[class*="block-grid-"] {
  display: block;
  margin: -(@grid-gutter-width/2);
  padding: 0;
  .clear-fix();
}

.block-grid-item {
  display: inline;
  margin: 0;
  padding: (@grid-gutter-width/2);
  height: auto;
  float: left;
  width: 100%;
  list-style: none;  // for those who like to use `li` elements as block-grid-items
}

.block-grid (@per-row) {
  & > .block-grid-item {
    width: (100%/@per-row);

    @nth-equation: ~"@{per-row}n+1";
    &:nth-of-type(n) { clear: none; }
    &:nth-of-type(@{nth-equation}) { clear: both; }
  }
}

// Recursive loop that produces rules for block grids of @per-row many items
// per row down to 1 many items per row.
.block-grids(@per-row: @grid-columns) when (@per-row > 0) {
  .block-grid-@{per-row} { .block-grid(@per-row); }
  .block-grids(@per-row - 1);
}

.block-grids();
